import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '../../components/form/TextField';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Grid from '../../components/layout/Grid';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import EditIcon from '../../components/icon/EditIcon';
import DeleteIcon from '../../components/icon/DeleteIcon';
import CremationOrderFragment from '../../fragments/CremationOrder';
import AddressAutocomplete, { createAddressComponentFields } from '../../components/form/AddressAutocomplete';
import Button from '../../components/form/Button';
import AlertModal from '../../components/modal/AlertModal';
import { numberToWords } from '../../utils/strings';

const clientPostalComponentFields = createAddressComponentFields('Client.PostalAddress');
const clientResidentialComponentFields = createAddressComponentFields('Client.ResidentialAddress');

class ContactDetailsTab extends Component {
    state = {
        readonlyClientDetails: true,
        editAuthorisedContactIndex: null,
        showDeleteAuthorisedContactAlertModal: false,
        authorisedContactToDelete: null
    };

    static getDerivedStateFromProps(newProps, oldState) {
        const { form } = newProps;
        const { currentRecordID } = oldState;
        if (!currentRecordID && form) {
            const setRecordID = form.getField('ID');
            const funeralID = form.getField('Funeral.ID');
            return ({
                currentRecordID: setRecordID,
                readonlyClientDetails: !!funeralID
            });
        }
        return null;
    }

    render() {
        const { showDeleteAuthorisedContactAlertModal } = this.state;

        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </ColumnLayout>

                <AlertModal
                    variant="warning"
                    open={showDeleteAuthorisedContactAlertModal}
                    title="Prompt"
                    primaryAction="Delete"
                    onClickPrimaryAction={() => this.handleDeleteAuthorisedContact()}
                    secondaryAction="Cancel"
                    onClickSecondaryAction={() => this.handleCloseDeleteAuthorisedContactModal()}
                    onClose={() => this.handleCloseDeleteAuthorisedContactModal()}
                >
                    <p>You're about to delete this authorised contact.</p>
                    <p>Are you sure?</p>
                </AlertModal>
            </Fragment>
        );
    }

    renderLeftColumn() {
        return <Fragment>{this.renderClientDetails()}</Fragment>;
    }

    renderClientDetails() {
        const { form } = this.props;
        const { readonlyClientDetails } = this.state;
        const funeralID = form.getField('Funeral.ID');
        return (
            <Grid container bucket>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings}>
                        <h4>Applicant Details</h4>
                        {!!funeralID && <IconButton
                            className="icon"
                            onClick={() => this.setState({ readonlyClientDetails: !readonlyClientDetails })}
                        >
                            <EditIcon />
                        </IconButton>}
                    </Inline>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="First name"
                        form={form}
                        name="Client.FirstName"
                        readOnly={readonlyClientDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Surname" form={form} name="Client.Surname" readOnly={readonlyClientDetails} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Relationship to deceased"
                        form={form}
                        name="Client.RelationshipToDeceased"
                        readOnly={readonlyClientDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Phone" form={form} name="Client.PrimaryNumber" readOnly={readonlyClientDetails} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Mobile"
                        form={form}
                        name="Client.SecondaryNumber"
                        readOnly={readonlyClientDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Email" form={form} name="Client.Email" readOnly={readonlyClientDetails} />
                </Grid>

                <Grid item xs={12}>
                    <AddressAutocomplete
                        allowCustomAddress
                        label="Residential Address"
                        form={form}
                        componentFields={clientResidentialComponentFields}
                        readOnly={readonlyClientDetails}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AddressAutocomplete
                        allowCustomAddress
                        label="Postal Address"
                        form={form}
                        componentFields={clientPostalComponentFields}
                        readOnly={readonlyClientDetails}
                    />
                </Grid>
            </Grid>
        );
    }

    renderRightColumn() {
        return <Fragment>{this.renderAuthorisedContacts()}</Fragment>;
    }

    renderAuthorisedContacts() {
        const { form } = this.props;
        const authorisedContacts = form.getState('AuthorisedContacts') || [];
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Authorised Contact</h4>
                </Grid>

                {authorisedContacts.map((authorisedContact, index) =>
                    this.renderAuthorisedContact(authorisedContact, index)
                )}

                <Grid item>
                    <Button variant="primary" onClick={() => this.handleAddAuthorisedContact()}>
                        + Add Authorised Contact{' '}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    renderAuthorisedContact(authorisedContact, index) {
        const { form } = this.props;
        const { editAuthorisedContactIndex } = this.state;

        const authorisedContactPostalAddressComponentFields = createAddressComponentFields(
            'AuthorisedContacts[' + index + '].PostalAddress'
        );
        const authorisedContactResidentialAddressComponentFields = createAddressComponentFields(
            'AuthorisedContacts[' + index + '].ResidentialAddress'
        );

        return (
            <Grid key={index} itemBucket>
                <Grid variant="justify" item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                        <h5>Authorised Contact - {numberToWords(index + 1)}</h5>

                        <IconButton
                            className="icon"
                            title={'Edit'}
                            onClick={() => this.handleToggleEditAuthorisedContactModal(index)}
                        >
                            <EditIcon />
                        </IconButton>

                        <IconButton
                            className="icon"
                            title={'Delete'}
                            onClick={() => this.handleShowDeleteAuthorisedContactModal(authorisedContact)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Inline>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="First name"
                        form={form}
                        name={`AuthorisedContacts[${index}].FirstName`}
                        readOnly={editAuthorisedContactIndex !== index}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Surname"
                        form={form}
                        name={`AuthorisedContacts[${index}].Surname`}
                        readOnly={editAuthorisedContactIndex !== index}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Relationship to deceased"
                        form={form}
                        name={`AuthorisedContacts[${index}].RelationshipToDeceased`}
                        readOnly={editAuthorisedContactIndex !== index}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Phone"
                        form={form}
                        name={`AuthorisedContacts[${index}].PrimaryNumber`}
                        readOnly={editAuthorisedContactIndex !== index}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Mobile"
                        form={form}
                        name={`AuthorisedContacts[${index}].SecondaryNumber`}
                        readOnly={editAuthorisedContactIndex !== index}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Email"
                        form={form}
                        name={`AuthorisedContacts[${index}].Email`}
                        readOnly={editAuthorisedContactIndex !== index}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AddressAutocomplete
                        allowCustomAddress
                        label="Residential Address"
                        form={form}
                        componentFields={authorisedContactResidentialAddressComponentFields}
                        readOnly={editAuthorisedContactIndex !== index}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AddressAutocomplete
                        allowCustomAddress
                        label="Postal Address"
                        form={form}
                        componentFields={authorisedContactPostalAddressComponentFields}
                        readOnly={editAuthorisedContactIndex !== index}
                    />
                </Grid>

                <Grid item>
                    <TextField
                        label="Notes"
                        name={`AuthorisedContacts[${index}].Notes`}
                        form={form}
                        multiline
                        readOnly={editAuthorisedContactIndex !== index}
                    />
                </Grid>
            </Grid>
        );
    }

    handleAddAuthorisedContact() {
        const { form } = this.props;
        const AuthorisedContacts = form.getState('AuthorisedContacts') || [];
        AuthorisedContacts.push({});
        form.setState({ AuthorisedContacts });
        this.handleToggleEditAuthorisedContactModal(AuthorisedContacts.length - 1);
    }

    handleToggleEditAuthorisedContactModal(index) {
        if (this.state.editAuthorisedContactIndex !== index) {
            this.setState({ editAuthorisedContactIndex: index });
        } else {
            this.setState({ editAuthorisedContactIndex: null });
        }
    }

    handleShowDeleteAuthorisedContactModal(authorisedContact) {
        this.setState({ authorisedContactToDelete: authorisedContact });
        this.setState({ showDeleteAuthorisedContactAlertModal: true });
    }

    handleCloseDeleteAuthorisedContactModal() {
        this.setState({ authorisedContactToDelete: null });
        this.setState({ showDeleteAuthorisedContactAlertModal: false });
    }

    handleDeleteAuthorisedContact() {
        const { form } = this.props;
        const AuthorisedContacts = form.getState('AuthorisedContacts') || [];

        let authorisedContact = this.state.authorisedContactToDelete;

        let authorisedContactIndex = AuthorisedContacts.indexOf(authorisedContact);
        if (authorisedContactIndex !== -1) {
            AuthorisedContacts.splice(authorisedContactIndex, 1);
            form.setState({ AuthorisedContacts });
        }

        this.setState({ authorisedContactToDelete: null });
        this.setState({ showDeleteAuthorisedContactAlertModal: false });
    }
}

export default {
    id: 'ContactDetails',
    label: 'Contact Details',
    component: withStyles({})(ContactDetailsTab),
    fragment: CremationOrderFragment,
    validation: {
        required: ['Client.FirstName']
    }
};
