import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import ReactToPrint from 'react-to-print';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import cloneDeep from 'lodash.clonedeep';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/es/Hidden/Hidden';
import moment from 'moment';
import Query from 'react-apollo/Query';
import Grid from '../../components/layout/Grid';
import Paper from '../../components/layout/Paper';
import Button from '../../components/form/Button';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import PrintIcon from '../../components/icon/PrintIcon';
import CremationRunsheet from '../../components/table/CremationRunsheet';
import PlaqueRunsheet from '../../components/table/PlaqueRunsheet';
import LinearProgressIndicator from '../../components/loading/LinearProgressIndicator';
import DispatchDate from '../../fragments/DispatchDate';
import SpecialInstruction from '../../fragments/SpecialInstruction';
import ReflectionRoom from '../../fragments/ReflectionRoom';
import Deceased from '../../fragments/Deceased';
import Member from '../../fragments/Member';
import { flattenConnection } from '../../utils/objects';
import DatePickerField from '../../components/form/DatePickerField';
import { GQL_REFETCH_60S, subscribe } from '../../utils/subscriptions';
import ErrorFallback from '../../components/ErrorFallback';
import { withErrorBoundary } from '@sentry/react';

class Runsheet extends Component {
    constructor(props) {
        super(props);

        const saved = JSON.parse(sessionStorage.getItem('runsheet')) || {};
        const date = saved.date || moment().format('YYYYMMDD');

        this.state = {
            dateFrom: moment(date).format('YYYY-MM-DD'),
            dateTo: moment(date).format('YYYY-MM-DD')
        };

        this.runsheetQueryRef = React.createRef();
        subscribe(this.runsheetQueryRef, GQL_REFETCH_60S);
        this.runsheetQuery2Ref = React.createRef();
        subscribe(this.runsheetQuery2Ref, GQL_REFETCH_60S);
    }

    render() {
        return (
            <Fragment>
                {this.renderHeader()}
                <Grid container>
                    <Grid item>
                        <Paper elevation={0}>
                            <div className="runsheet-paper" ref={e => (this.formRef = e)}>
                                {this.renderPageContent()}
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderHeader() {
        const { dateFrom, dateTo } = this.state;
        return (
            <Grid container mr={10}>
                <Grid item xs={12} sm={12} md={3}>
                    <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                        <h1 className="title">Run Sheet</h1>
                        <Hidden mdUp>
                            <ReactToPrint
                                pageStyle={
                                    '@media print { body { -webkit-print-color-adjust: exact; color-adjust: exact; } }'
                                }
                                trigger={() => (
                                    <Button variant="primary icon-button">
                                        <PrintIcon />
                                    </Button>
                                )}
                                content={() => this.formRef}
                            />
                        </Hidden>
                    </Inline>
                </Grid>
                <Grid item xs={6} md={3}>
                    <DatePickerField label="From" type="date" value={dateFrom} name="dateFrom" onChange={this.dateChange} />
                </Grid>
                <Grid item xs={6} md={3}>
                    <DatePickerField label="Until" type="date" value={dateTo} name="dateTo" onChange={this.dateChange} />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Hidden smDown>
                        <Inline className="button-alignment" alignment={inlineAlignment.right}>
                            <ReactToPrint
                                pageStyle={
                                    '@media print { body { -webkit-print-color-adjust: exact; color-adjust: exact; } }'
                                }
                                trigger={() => (
                                    <Button variant="primary">
                                        <PrintIcon />
                                        <Hidden smDown>Print</Hidden>
                                    </Button>
                                )}
                                content={() => this.formRef}
                            />
                        </Inline>
                    </Hidden>
                </Grid>
            </Grid>
        );
    }

    dateChange = e => {
        const { dateFrom, dateTo } = this.state;
        const diff = moment(dateTo).diff(dateFrom, 'days');
        // enforce a maximum of 7 days in the runsheet.
        const newDates = { dateFrom, dateTo };

        if (e.target.name === 'dateFrom') {
            if (moment(e.target.value).isAfter(dateTo)) {
                newDates.dateFrom = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateTo = moment(e.target.value)
                    .add(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (moment(e.target.value).isBefore(moment(dateTo).subtract(7, 'days'))) {
                newDates.dateFrom = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateTo = moment(e.target.value)
                    .add(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (!!e.target.value) {
                newDates.dateFrom = e.target.value;
            }
        } else {
            if (moment(e.target.value).isBefore(dateFrom)) {
                newDates.dateTo = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateFrom = moment(e.target.value)
                    .subtract(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (moment(e.target.value).isAfter(moment(dateFrom).add(7, 'days'))) {
                newDates.dateTo = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateFrom = moment(e.target.value)
                    .subtract(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (!!e.target.value) {
                newDates.dateTo = e.target.value;
            }
        }
        sessionStorage.setItem('runsheet', JSON.stringify({ date: moment(newDates.dateFrom).format('YYYYMMDD') }));

        this.setState(newDates);
    };

    renderPageContent() {
        const { dateFrom, dateTo } = this.state;

        return (
            <Fragment>
                <Query query={cremationsQuery} variables={{ fromDate: dateFrom, toDate: dateTo }} ref={this.runsheetQueryRef}>
                    {response1 => {
                        if (response1.loading && response1.networkStatus === 1) {
                            return (
                                <Fragment>
                                    <div>Loading data, please wait a moment...</div>
                                    <LinearProgressIndicator />
                                </Fragment>
                            );
                        }

                        return (
                            <Query query={plaquesQuery} variables={{ fromDate: dateFrom, toDate: dateTo }} ref={this.runsheetQuery2Ref}>
                                {response2 => {
                                    if (response2.loading && response2.networkStatus === 1) {
                                        return (
                                            <Fragment>
                                                <div>Loading data, please wait a moment...</div>
                                                <LinearProgressIndicator />
                                            </Fragment>
                                        );
                                    }

                                    const data = { ...response1.data, ...response2.data };
                                    return this.renderDays(data, response2.networkStatus);
                                }}
                            </Query>
                        );
                    }}
                </Query>
            </Fragment>
        );
    }

    renderDays(data, networkStatus) {
        const { dateFrom, dateTo } = this.state;
        const heldOverCremations = data['heldOverCremOrders'].edges.map(e => e.node);
        const getDays = () => {
            let arr = {};
            let dt = dateFrom;
            while (moment(dt) <= moment(dateTo)) {
                arr[moment(dt).format('YYYY-MM-DD')] = [];
                dt = moment(dt).add(1, 'd');
            }
            return arr;
        };
        let daylist = getDays();
        daylist = this.addUniqueDaysToDays(data, daylist);

        let days = [];
        for (let item in daylist) {
            days.push(item);
        }

        return (
            <Fragment>
                {!!networkStatus && networkStatus !== 7 && (
                    <Fragment>
                        <div>Loading data, please wait a moment...</div>
                        <LinearProgressIndicator />
                    </Fragment>
                )}
                <Grid container>
                    <Grid item>
                        {days.map((item, i, a) => {
                            const hasReadyCremOrders = !!daylist[days[i]] && !!daylist[days[i]].ReadyCremOrders;
                            const hasReadyPlaqueOrders = !!daylist[days[i]] && !!daylist[days[i]].ReadyPlaqueOrders;

                            return (
                                <Fragment key={i}>
                                    <h2>Daily Run Sheet for {moment(item).format('dddd[,] LL')}</h2>

                                    {moment().isSame(item, 'day') &&
                                        !!heldOverCremations.length &&
                                        this.renderPriorityTable(heldOverCremations)}

                                    {hasReadyCremOrders && this.renderCremationTable(daylist[days[i]])}

                                    {!hasReadyCremOrders && (
                                        <p className="no-actions">
                                            There are <span>no cremations</span> on this day.
                                        </p>
                                    )}

                                    {hasReadyPlaqueOrders && this.renderPlaqueTable(daylist[days[i]])}

                                    {!hasReadyPlaqueOrders && (
                                        <p className="no-actions">
                                            There are <span>no plaque installations</span> on this day.
                                        </p>
                                    )}
                                </Fragment>
                            );
                        })}
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    addUniqueDaysToDays(data, daylist) {
        let ReadyCremOrders = [].concat(
            this.FilterByDate(data, 'readyCremOrders', 'CremationDate'),
            this.FilterByDate(data, 'notReadyCremOrders', 'CremationDate')
        );

        let ReadyPlaqueOrders = [].concat(
            this.FilterByDate(data, 'readyPlaqueOrders', 'InstallationDate'),
            this.FilterByDate(data, 'urgentPlaqueOrders', 'InstallationDate')
        );

        //cycle through appointments and create unique days
        return this.getUniqueDates(
            daylist,
            ReadyCremOrders.sort((a, b) => moment(a.CremationDate) - moment(b.CremationDate)),
            ReadyPlaqueOrders.sort((a, b) => moment(a.InstallationDate) - moment(b.InstallationDate)),
            'Date'
        );
    }

    FilterByDate(data, node, dateObj) {
        const { dateFrom, dateTo } = this.state;
        const original = cloneDeep(data);
        flattenConnection(original, [node]);

        let datesTimesAdded = [];

        !!original && original[node].forEach(function(item) {
            let date = moment(item[dateObj]);
            if (date.isBetween(dateFrom, dateTo, 'day', '[]')) {
                item['Date'] = date.format('YYYY-MM-DD');
                item['Time'] = date.format('HH:mm:ss');
                item['Type'] = node;
                datesTimesAdded.push(item);
            }
        });

        return datesTimesAdded;
    }

    getUniqueDates(days, ReadyCremOrders, ReadyPlaqueOrders, key) {
        ReadyCremOrders.forEach(function(item) {
            if (!(item[key] in days)) {
                days[item[key]] = [];
            }
            let tempDays = days[item[key]];
            if (!tempDays.ReadyCremOrders) {
                tempDays.ReadyCremOrders = [];
                tempDays.ReadyCremOrders.push(item);
            } else {
                tempDays.ReadyCremOrders.push(item);
            }
        });

        ReadyPlaqueOrders.forEach(function(item) {
            if (!(item[key] in days)) {
                days[item[key]] = [];
            }
            let tempDays = days[item[key]];
            if (!tempDays.ReadyPlaqueOrders) {
                tempDays.ReadyPlaqueOrders = [];
                tempDays.ReadyPlaqueOrders.push(item);
            } else {
                tempDays.ReadyPlaqueOrders.push(item);
            }
        });

        return days;
    }

    renderPriorityTable = heldOverCremations => {
        return (
            <Fragment>
                <h3>Priority Actions</h3>
                {!!heldOverCremations.length &&
                    heldOverCremations.map(item => {
                        return this.renderCremationRunSheetItem(item, 'priority', 'PRIORITY');
                    })}
            </Fragment>
        );
    };

    renderCremationTable = node => {
        return (
            <Fragment>
                <h3>Cremations</h3>
                {node.ReadyCremOrders.map(item => {
                    const varStat = {
                        variant:
                            !item.Type || (item.Type === 'readyCremOrders' && !item.SameDayCremation) ? '' : 'priority',
                        status: item.Type === 'notReadyCremOrders' ? 'NOT READY' : 'READY'
                    };
                    return this.renderCremationRunSheetItem(item, varStat.variant, varStat.status);
                })}
            </Fragment>
        );
    };

    renderCremationRunSheetItem = (cremation, variant, status) => {
        return (
            <CremationRunsheet
                key={'cr' + cremation.ID}
                variant={variant}
                runsheetStatus={status}
                runsheetType="cremation"
                cremation={cremation}
            >
                {cremation.SpecialInstructions.map(specialInstruction => (
                    <p key={specialInstruction.ID}>{specialInstruction.Title + ' : ' + specialInstruction.Comment}</p>
                ))}
            </CremationRunsheet>
        );
    };

    renderPlaqueTable = node => {
        return (
            <Fragment>
                <h3>Plaque Installations</h3>
                {node.ReadyPlaqueOrders.map(item => {
                    const varStat = {
                        variant: !item.Type || item.Type === 'readyPlaqueOrders' ? '' : 'priority',
                        status: item.Type === 'urgentPlaqueOrders' ? 'URGENT' : 'READY'
                    };
                    return this.renderPlaqueRunSheetItem(item, varStat.variant, varStat.status);
                })}
            </Fragment>
        );
    };

    renderPlaqueRunSheetItem = (plaque, variant, status) => {
        return (
            <PlaqueRunsheet
                key={'pl' + plaque.ID}
                variant={variant}
                runsheetStatus={status}
                runsheetType="plaque installation"
                plaque={plaque}
            />
        );
    };
}

const cremationsQuery = gql`
    ${DispatchDate}
    ${SpecialInstruction}
    ${ReflectionRoom}
    ${Deceased}
    ${Member}

    fragment CremationOrderFrag on CremationOrder {
        ID
        LegacyKey
        ContainmentStyle
        EstimatedDeceasedWeight
        DeceasedWeight
        CremationNumber
        CremationDate
        CremationTime
        CremationActionNotes
        CremationPending
        CremationComplete
        IsDeliveryOnly
        FuneralService
        SameDayCremation
        EstimatedInsertionDate
        ReadyForCremation
        HasMissingCertificates
        AdditionalNotes
        DispatchDates {
            ...DispatchDate
        }
        SpecialInstructions {
            ...SpecialInstruction
        }
        WitnessOfInsertionRequired
        ReflectionRoomRequired
        ReflectionRoom {
            ...ReflectionRoom
        }
        Deceased {
            ...Deceased
        }
        CremationOperator {
            ...Member
        }
        Funeral {
            ID
            PlaceOfService {
                ID
                Type
            }
        }
    }

    query($fromDate: String, $toDate: String) {
        heldOverCremOrders: readCremationOrders(isHeldOver: true, excludeCR: true) {
            edges {
                node {
                    ...CremationOrderFrag
                }
            }
        }
        readyCremOrders: readCremationOrders(fromDate: $fromDate, toDate: $toDate, isReady: true, excludeCR: true) {
            edges {
                node {
                    ...CremationOrderFrag
                }
            }
        }
        notReadyCremOrders: readCremationOrders(fromDate: $fromDate, toDate: $toDate, isReady: false, excludeCR: true) {
            edges {
                node {
                    ...CremationOrderFrag
                }
            }
        }
    }
`;
const plaquesQuery = gql`
    ${Deceased}
    fragment PlaqueOrderFragment on PlaqueOrder {
        ID
        LegacyKey
        Status
        InstallationDate
        InstallationTime
        FamilyAttendance
        FamilyContact
        FamilyAttendanceDate
        FamilyAttendanceTime
        PlacementByCouncil
        PlaquePosition
        PlaqueMaterial
        AttachmentMethod
        WallPosition
        QuoteSignedDate
        Deceased {
            ...Deceased
        }
        Ashes {
            ID
            Ashes
            ContainmentStyle
            Comment
        }
        PlaqueCombination {
            ID
            LocationType
        }
        PlaqueLocation {
            ID
            Name
            AddressLine1
            AddressLine2
            State
            Suburb
            Postcode
        }
    }

    query($fromDate: String, $toDate: String) {
        urgentPlaqueOrders: readPlaqueOrders(fromDate: $fromDate, toDate: $toDate, isUrgent: true) {
            edges {
                node {
                    ...PlaqueOrderFragment
                }
            }
        }
        readyPlaqueOrders: readPlaqueOrders(fromDate: $fromDate, toDate: $toDate, isUrgent: false) {
            edges {
                node {
                    ...PlaqueOrderFragment
                }
            }
        }
    }
`;

export default withErrorBoundary(compose(withRouter, withStyles({}))(Runsheet), { fallback: ErrorFallback });
