import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import AttentionDot from '../../components/table/AttentionDot';
import Grid from '../../components/layout/Grid';
import Paper from '../../components/layout/Paper';
import DataTable from '../../components/dataTable/DataTable';
import ViewHeader from '../../components/header/ViewHeader';
import Button from '../../components/form/Button';
import { joinDefined, prettyPhone } from '../../utils/strings';
import { isNullOrUndefined } from '../../utils/objects';
import { niceDateTimeFromString } from '../../utils/date';
import { DispatchMethodOptions } from '../../components/ComponentConstants';
import ErrorFallback from '../../components/ErrorFallback';
import { withErrorBoundary } from '@sentry/react';

class CremationRecordList extends Component {
    render() {
        const { history } = this.props;
        const viewAll = history.location.pathname.indexOf('/view-all') > 0;
        return (
            <Grid container>
                <Grid item>{this.renderHeader(viewAll)}</Grid>
                <Grid item>
                    <Paper elevation={0}>
                        <Grid item>{this.renderCremationListingsTable(viewAll)}</Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }

    renderHeader(viewAll) {
        return <ViewHeader title="Cremations" subtitle={viewAll ? 'View All Records' : 'Current Orders'} />;
    }

    renderCremationListingsTable(viewAll) {
        const filters =
            (!viewAll && [
                { field: 'CremationPending', value: '0' },
                { field: 'CremationCancelled', value: '0' },
                { field: 'CremationComplete', value: '0' }
            ]) ||
            null;
        return (
            <Fragment>
                <Grid item>
                    <Grid container>
                        <Grid item>
                            <DataTable
                                dataObject="CremationOrder"
                                queryName="readCremationOrders"
                                fragment={cremationFragment}
                                isFilterable
                                isSortable
                                columns={cremationColumns}
                                variables={{ limit: 10, filters, filterBy: [] }}
                                brandProperty="LegacyKey" //the property to determine the brand from
                                searchVariable="contains"
                                tableTitle={viewAll ? 'Records Archive' : 'Outstanding Cremations'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

const cremationColumns = [
    {
        label: 'Ref',
        propertyPath: 'LegacyKey',
        renderCell: (row, value) => {
            const approvedAllRequiredDocs = !row.HasMissingCertificates;
            const urgent = row.Status === 'Urgent' || row.Status === 'Overdue' || !!row.SameDayCremation;
            return (
                <Fragment>
                    {(!approvedAllRequiredDocs || !!urgent) && <AttentionDot />}
                    <Button href={'/cremations/' + row.ID} variant="link-orange">
                        {value || row.ID}
                    </Button>
                </Fragment>
            );
        }
    },
    {
        label: 'Cremation Number',
        propertyPath: 'CremationNumber',
        renderCell: row => <span>{row.CremationNumber ? row.CremationNumber : ''}</span>
    },
    {
        label: 'Deceased',
        propertyPath: 'Deceased.Surname',
        renderCell: row => <span>{row.NameOfDeceased}</span>
    },
    {
        label: 'Cremation Date/Time',
        propertyPath: 'CremationDate',
        renderCell: row => (
            <span>{niceDateTimeFromString(joinDefined([row.CremationDate, row.CremationTime], ' '))}</span>
        )
    },
    {
        label: 'Authorised Contact',
        renderCell: row => {
            let firstAuthContact = row.AuthorisedContacts[0];

            if (isNullOrUndefined(firstAuthContact)) {
                return null;
            }

            return (
                <span>
                    {joinDefined(
                        [firstAuthContact.Surname, firstAuthContact.FirstName, firstAuthContact.MiddleName],
                        ' '
                    )}
                    {(firstAuthContact.PrimaryNumber || firstAuthContact.SecondaryNumber) &&
                        (firstAuthContact.FirstName || firstAuthContact.MiddleName || firstAuthContact.Surname) &&
                        ' - '}
                    {prettyPhone(
                        joinDefined([firstAuthContact.PrimaryNumber, firstAuthContact.SecondaryNumber], ' / ')
                    )}
                </span>
            );
        }
    },
    {
        label: 'Applicant',
        propertyPath: 'Client.Surname',
        renderCell: row => (
            <span>
                {row.Client && joinDefined([row.Client.FirstName, row.Client.MiddleName, row.Client.Surname], ' ')}
                {row.Client &&
                    (row.Client.PrimaryNumber || row.Client.SecondaryNumber) &&
                    (row.Client.FirstName || row.Client.MiddleName || row.Client.Surname) &&
                    ' - '}
                {row.Client && prettyPhone(joinDefined([row.Client.PrimaryNumber, row.Client.SecondaryNumber], ' / '))}
            </span>
        )
    },
    {
        label: 'Status',
        renderCell: row => <span>{row.Status}</span>
    },
    {
        label: 'Dispatch Date',
        renderCell: row => {
            if (!(row.DispatchDates && row.DispatchDates.length)) return null;
            const dispatchDate = row.DispatchDates[0];
            return (
                <span>
                    {joinDefined(
                        [niceDateTimeFromString(joinDefined([dispatchDate.Date, dispatchDate.Time], ' '))],
                        ' '
                    )}
                </span>
            );
        }
    },
    {
        label: 'Dispatch',
        renderCell: row => {
            if (!(row.DispatchDates && row.DispatchDates.length)) return null;
            const dispatchDates = [].concat(row.DispatchDates.filter(d => !d.DispatchComplete));
            if (!dispatchDates.length) {
                return 'Completed';
            }
            const dispatchDate = dispatchDates[0];
            const dispatchMethod = DispatchMethodOptions.find(e => e.value === dispatchDate.DispatchMethod);
            return (
                <span>
                    {joinDefined(
                        [
                            dispatchMethod && dispatchMethod.label,
                            dispatchDate.AshesCollectedBy && `(${dispatchDate.AshesCollectedBy})`
                        ],
                        ' '
                    )}
                </span>
            );
        }
    }
];

const cremationFragment = gql`
    fragment CremationOrderRecord on CremationOrder {
        ID
        LegacyKey
        ContainmentStyle
        CremationNumber
        CremationDate
        CremationTime
        HasMissingCertificates
        NameOfDeceased
        Status
        DispatchDates {
            ID
            Date
            Time
            DispatchMethod
            AshesCollectedBy
            DispatchComplete
        }
        Client {
            ID
            FirstName
            MiddleName
            Surname
            PrimaryNumber
            SecondaryNumber
        }
        AuthorisedContacts {
            ID
            FirstName
            MiddleName
            Surname
            PrimaryNumber
            SecondaryNumber
        }
        SameDayCremation
    }
`;

export default withErrorBoundary(compose(withRouter, withStyles({}))(CremationRecordList), { fallback: ErrorFallback });
export const CremationRecordAllList = withStyles({})(({ ...props }) => {
    return <CremationRecordList {...props} />;
});
