import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { isNullOrUndefined } from '../../utils/objects';
import { joinDefined } from '../../utils/strings';

export default withStyles({})(
    ({ margin = 0, pc, className, children, bucket, itemBucket, spacing, xs, sm, md, lg, xl, item, container, key }) => {
        if (container) {
            if (isNullOrUndefined(spacing)) spacing = 24;

            if (isNullOrUndefined(pc)) pc = 1;
        }

        let pcProps;
        if (typeof pc !== 'undefined' || bucket === true || itemBucket === true) {
            pcProps = {
                item: true,
                xs: 12,
                sm: Math.floor(12 * (pc || 1))
            };
        }

        if (item && isNullOrUndefined(xs)) xs = 12;

        if (bucket) {
            pcProps.container = true;
            pcProps.spacing = spacing || 16;
        } else if (itemBucket) {
            pcProps.container = true;
            pcProps.spacing = spacing || 16;
            margin = 'revert-layer'
        }

        const classNames = joinDefined([className], ' ');

        if (bucket || itemBucket) {
            return (
                <Grid
                    style={{ margin }}
                    className={`grid grid--bucket ${classNames}`}
                    spacing={spacing}
                    xs={xs}
                    sm={sm}
                    md={md}
                    lg={lg}
                    xl={xl}
                    item={item}
                    container={container}
                    key={key}
                    {...pcProps}
                >
                    {children}
                </Grid>
            );
        }

        return (
            <Grid
                className={`grid ${classNames}`}
                style={{ margin }}
                spacing={spacing}
                xs={xs}
                sm={sm}
                md={md}
                lg={lg}
                xl={xl}
                item={item}
                container={container}
                key={key}
                {...pcProps}
            >
                {children}
            </Grid>
        );
    }
);
