import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../layout/Grid';
import Label from './Label';

const ReadonlyField = ({ label, children, className }) => (
    <div className="readonly">
        <Grid container spacing={8} margin={false}>
            {label && (
                <Grid item>
                    <Label className={`form-label ${className || ''}`}>{label}</Label>
                </Grid>
            )}
            <Grid item>
                <span className="value">{children}</span>
            </Grid>
        </Grid>
    </div>
);

export default withStyles({})(ReadonlyField);
