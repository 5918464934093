import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '../../components/form/Typography';
import TextField from '../../components/form/TextField';
import Checkbox from '../../components/form/Checkbox';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Grid from '../../components/layout/Grid';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import Button from '../../components/form/Button';
import EditIcon from '../../components/icon/EditIcon';
import DeleteIcon from '../../components/icon/DeleteIcon';
import AshLocationHistoryModal from './Modals/AshLocationHistoryModal';
import Select from '../../components/form/Select';
import AlertModal from '../../components/modal/AlertModal';
import {
    AshesCollectedByOptions,
    DispatchMethodOptions,
    GenderOptions,
    MaritalStatusOptions
} from '../../components/ComponentConstants';
import CremationOrderFragment from '../../fragments/CremationOrder';
import AddressAutocomplete, { createAddressComponentFields } from '../../components/form/AddressAutocomplete';
import { numberToWords } from '../../utils/strings';
import SpecialInstructionModal from './Modals/SpecialInstructionModal';
import { niceDateFromString, niceDateTimeFromString, niceTimeFromString } from '../../utils/date';
import moment from 'moment';
import RadioGroup from '../../components/form/RadioGroup';
import Table, { Cell, HeaderRow, Row } from '../../components/table/Table';
import ReflectionRoomBookingModal from './Modals/ReflectionRoomBookingModal';
import ReadonlyField from '../../components/form/ReadonlyField';
import StaffAutoComplete from '../../components/form/StaffAutoComplete';
import UserCircle from '../../components/form/UserCircle';
import Label from '../../components/form/Label';

const deceasedAddressComponentFields = createAddressComponentFields('Deceased.ResidentialAddress');

class RecordDetailsTab extends Component {
    state = {
        showAshLocationHistoryModal: false,
        showSpecialInstructionModal: false,
        showDeleteDispatchDateAlertModal: false,
        showDeleteInstructionAlertModal: false,
        showDeleteAshLocationAlertModal: false,
        showReflectionRoomBookingModal: false,
        showDeleteReflectionRoomBookingAlertModal: false,
        editAshLocationHistoryIndex: null,
        editSpecialInstructionIndex: null,
        dispatchDateToDelete: null,
        readonlyDeceasedDetails: true,
        readonlyCremationDetails: true
    };

    static getDerivedStateFromProps(newProps, oldState) {
        const { form } = newProps;
        const { currentRecordID } = oldState;
        if (!currentRecordID && form) {
            const setRecordID = form.getField('ID');
            const funeralID = form.getField('Funeral.ID');
            return ({
                currentRecordID: setRecordID,
                readonlyDeceasedDetails: !!funeralID,
                readonlyCremationDetails: !!funeralID
            });
        }
        return null;
    }

    render() {
        const { showAshLocationHistoryModal } = this.state;
        const { showSpecialInstructionModal } = this.state;
        const { showDeleteDispatchDateAlertModal } = this.state;
        const { showDeleteInstructionAlertModal } = this.state;
        const { showDeleteAshLocationAlertModal } = this.state;
        const { showReflectionRoomBookingModal } = this.state;
        const { showDeleteReflectionRoomBookingAlertModal } = this.state;
        const { editAshLocationHistoryIndex } = this.state;
        const { editSpecialInstructionIndex } = this.state;
        const { form } = this.props;

        let editAshLocationHistory = {
            ID: null,
            Location: '',
            LocationDetail: '',
            Notes: '',
            Changes: []
        };

        let editSpecialInstruction = {
            ID: null,
            Title: '',
            Comment: '',
            Confirmed: false
        };

        if (editAshLocationHistoryIndex !== null && editAshLocationHistoryIndex !== undefined) {
            editAshLocationHistory = form.getState('AshLocations')[editAshLocationHistoryIndex];
        }

        if (editSpecialInstructionIndex !== null && editSpecialInstructionIndex !== undefined) {
            editSpecialInstruction = form.getState('SpecialInstructions')[editSpecialInstructionIndex];
        }

        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </ColumnLayout>

                <AshLocationHistoryModal
                    open={showAshLocationHistoryModal}
                    onClose={() => this.handleCloseAshLocationHistoryModal()}
                    onCancel={() => this.handleCloseAshLocationHistoryModal()}
                    onSubmit={ashLocationHistory => this.handleSaveAshLocationHistoryModal(ashLocationHistory)}
                    ashLocationHistory={editAshLocationHistory}
                    name={'editAshLocationHistory'}
                />

                <SpecialInstructionModal
                    open={showSpecialInstructionModal}
                    onClose={() => this.handleCloseSpecialInstructionModal()}
                    onCancel={() => this.handleCloseSpecialInstructionModal()}
                    onSubmit={specialInstruction => this.handleSaveSpecialInstructionModal(specialInstruction)}
                    specialInstruction={editSpecialInstruction}
                    name={'editSpecialInstruction'}
                />

                <ReflectionRoomBookingModal
                    open={showReflectionRoomBookingModal}
                    data={form.getField('ReflectionRoom')}
                    onClose={newItem => this.handleCloseReflectionRoomBookingModal(newItem)}
                />

                <AlertModal
                    variant="warning"
                    open={showDeleteReflectionRoomBookingAlertModal}
                    title="Prompt"
                    primaryAction="Delete"
                    onClickPrimaryAction={() => this.handleDeleteReflectionRoomBooking()}
                    secondaryAction="Cancel"
                    onClickSecondaryAction={() => this.handleCloseDeleteReflectionRoomBookingAlertModal()}
                    onClose={() => this.handleCloseDeleteReflectionRoomBookingAlertModal()}
                >
                    <p>You're about to delete this reflection room booking.</p>
                    <p>Are you sure?</p>
                </AlertModal>

                <AlertModal
                    variant="warning"
                    open={showDeleteInstructionAlertModal}
                    title="Prompt"
                    primaryAction="Delete"
                    onClickPrimaryAction={() => this.handleDeleteSpecialInstruction()}
                    secondaryAction="Cancel"
                    onClickSecondaryAction={() => this.handleCloseDeleteSpecialInstructionAlertModal()}
                    onClose={() => this.handleCloseDeleteSpecialInstructionAlertModal()}
                >
                    <p>You're about to delete this special instruction.</p>
                    <p>Are you sure?</p>
                </AlertModal>

                <AlertModal
                    variant="warning"
                    open={showDeleteAshLocationAlertModal}
                    title="Prompt"
                    primaryAction="Delete"
                    onClickPrimaryAction={() => this.handleDeleteAshLocationAlertModal()}
                    secondaryAction="Cancel"
                    onClickSecondaryAction={() => this.handleCloseDeleteAshLocationAlertModal()}
                    onClose={() => this.handleCloseDeleteAshLocationAlertModal()}
                >
                    <p>You're about to delete this ash location history.</p>
                    <p>Are you sure?</p>
                </AlertModal>

                <AlertModal
                    variant="warning"
                    open={showDeleteDispatchDateAlertModal}
                    title="Prompt"
                    primaryAction="Delete"
                    onClickPrimaryAction={() => this.handleDeleteDispatchDate()}
                    secondaryAction="Cancel"
                    onClickSecondaryAction={() => this.handleCloseDeleteDispatchDateModal()}
                    onClose={() => this.handleCloseDeleteDispatchDateModal()}
                >
                    <p>You're about to delete this dispatch date.</p>
                    <p>Are you sure?</p>
                </AlertModal>
            </Fragment>
        );
    }

    renderLeftColumn() {
        return (
            <Fragment>
                {this.renderDeceasedDetails()}
                {this.renderCremationDetails()}
                {this.renderReflectionRoom()}
                {this.renderSpecialInstructions()}
            </Fragment>
        );
    }

    renderDeceasedDetails() {
        const { form } = this.props;
        const { readonlyDeceasedDetails } = this.state;
        const funeralID = form.getField('Funeral.ID');
        return (
            <Grid container bucket>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                        <h4>Deceased details</h4>
                        {!!funeralID && <IconButton
                            className="icon"
                            onClick={() => this.setState({ readonlyDeceasedDetails: !readonlyDeceasedDetails })}
                        >
                            <EditIcon />
                        </IconButton>}
                    </Inline>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="First name"
                        form={form}
                        name="Deceased.FirstName"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Middle name(s)"
                        form={form}
                        name="Deceased.MiddleName"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Surname" form={form} name="Deceased.Surname" readOnly={readonlyDeceasedDetails} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Select
                        label="Gender"
                        options={GenderOptions}
                        form={form}
                        name="Deceased.Gender"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Date of Birth"
                        type="date"
                        form={form}
                        name="Deceased.DateOfBirth"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Date of Death"
                        type="date"
                        form={form}
                        name="Deceased.DateOfDeath1"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Select
                        label="Marital Status"
                        options={MaritalStatusOptions}
                        form={form}
                        name="Deceased.MaritalStatus"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AddressAutocomplete
                        allowCustomAddress
                        label="Residential Address"
                        form={form}
                        componentFields={deceasedAddressComponentFields}
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>
            </Grid>
        );
    }

    renderCremationDetails() {
        const { form } = this.props;
        const { readonlyCremationDetails } = this.state;
        const funeralID = form.getField('Funeral.ID');
        const isDeliveryOnly = form.getField('IsDeliveryOnly');
        const funeralService = form.getField('FuneralService') || form.getField('Funeral.PlaceOfService.Type');
        const serviceOptions = [
            { label: 'No Service', value: 'No Service No Attendance' },
            { label: 'Full Service', value: 'Service' },
            { label: 'Unsure', value: '' }
        ];
        const idx = serviceOptions.findIndex(obj => obj.value === funeralService);
        if (funeralService && idx < 0) {
            serviceOptions.push({ label: funeralService, value: funeralService });
        }
        return (
            <Grid container bucket>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                        <h4>{!!funeralID ? 'Booking Requests' : 'Booking'}</h4>
                        {!!funeralID && <IconButton
                            className="icon"
                            onClick={() => this.setState({ readonlyCremationDetails: !readonlyCremationDetails })}
                        >
                            <EditIcon />
                        </IconButton>}
                    </Inline>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Requested Delivery Date"
                        type="date"
                        form={form}
                        name="CremationDate"
                        readOnly={readonlyCremationDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Requested Delivery Time"
                        type="time"
                        form={form}
                        name="CremationTime"
                        readOnly={readonlyCremationDetails}
                    />
                </Grid>

                {1===2 && this.renderCremationDateHistories()}

                <Grid item xs={12}>
                    <Select
                        label="Funeral Service Type"
                        form={form}
                        name="FuneralService"
                        options={serviceOptions}
                        value={funeralService}
                        readOnly={!!funeralID}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Checkbox
                        label="Delivery only"
                        form={form}
                        name="IsDeliveryOnly"
                        readOnly={readonlyCremationDetails}
                    />
                </Grid>

                {!isDeliveryOnly && (
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <Checkbox
                                label="Same-day Cremation"
                                form={form}
                                name="SameDayCremation"
                                disabled={readonlyCremationDetails}
                                readOnly={readonlyCremationDetails}
                            />
                        </Grid>

                        {this.renderWitnessOfInsertionRequired(readonlyCremationDetails)}
                        {this.renderReflectionRoomRequired(readonlyCremationDetails)}
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderCremationDateHistories() {
        const { form } = this.props;
        const cremationDateHistories = form.getState('CremationDateHistories') || [];
        if (cremationDateHistories.length < 2) return null;

        return (
            <Grid item>
                <ReadonlyField label="Cremation Request History">
                    {cremationDateHistories
                        .filter(e => !!e)
                        .sort((a, b) => (a.Created > b.Created ? -1 : 1))
                        .map(cremationDateHistory => (
                            <Fragment key={cremationDateHistory.ID}>
                                <Typography weight="light">
                                    Updated on {niceDateTimeFromString(cremationDateHistory.Created)}{' '}
                                </Typography>
                                <Typography>
                                    Requested Date: {niceDateFromString(cremationDateHistory.Date)}
                                    {cremationDateHistory.Time &&
                                        ', Requested Time: ' + niceTimeFromString(cremationDateHistory.Time)}
                                </Typography>
                            </Fragment>
                        ))}
                </ReadonlyField>
            </Grid>
        );
    }

    renderSpecialInstructions() {
        const { form } = this.props;
        const specialInstructions = form.getState('SpecialInstructions') || [];
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Special Instructions</h4>
                </Grid>

                {specialInstructions.length > 0 &&
                    specialInstructions.map((specialInstruction, index) => (
                        <Grid item key={index}>
                            <Inline alignment={inlineAlignment.rightAlignSiblings} center className="nowrap">
                                <div className="instruction">
                                    <p>{specialInstruction.Title}</p>
                                    <p className="comments">{specialInstruction.Comment}</p>
                                </div>
                                <Checkbox
                                    label="Confirmed"
                                    form={form}
                                    name={`SpecialInstructions[${index}].Confirmed`}
                                />
                                <IconButton
                                    className="icon"
                                    onClick={() => this.handleShowSpecialInstructionModal(index)}
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    className="icon"
                                    onClick={() => this.handleShowDeleteSpecialInstructionAlertModal(index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Inline>
                        </Grid>
                    ))}

                <Grid item>
                    <Button variant="primary" onClick={() => this.handleShowSpecialInstructionModal()}>
                        Add Special Instructions...
                    </Button>
                </Grid>
            </Grid>
        );
    }

    renderAshLocation() {
        const { form } = this.props;
        const ashLocations = form.getState('AshLocations') || [];
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Ash Location</h4>
                </Grid>

                {ashLocations.length > 0 &&
                    ashLocations.map((ashLocation, index) => (
                        <Grid item key={ashLocation.ID}>
                            <Inline nowrap alignment={inlineAlignment.rightAlignSiblings} center>
                                <div>
                                    <Typography component="p">
                                        {ashLocation.Location}
                                        {ashLocation.Ashes && ` (${ashLocation.Ashes})`}
                                    </Typography>
                                    <Typography component="p"><small>{ashLocation.LocationDetail}</small></Typography>
                                </div>
                                <IconButton
                                    className="icon"
                                    onClick={() => this.handleShowAshLocationHistoryModal(index)}
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    className="icon"
                                    onClick={() => this.handleShowDeleteAshLocationAlertModal()}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                {/*<IconButton className="icon" onClick={() => this.handleDeleteAshLocationHistory(index)}><DeleteIcon /></IconButton>*/}
                            </Inline>
                        </Grid>
                    ))}

                <Grid item>
                    <Button variant="primary" onClick={() => this.handleShowAshLocationHistoryModal()}>
                        Log Ash Location...
                    </Button>
                </Grid>
            </Grid>
        );
    }

    renderRightColumn() {
        return (
            <Fragment>
                {this.renderAshLocation()}
                {this.renderDispatchDates()}
            </Fragment>
        );
    }

    renderDispatchDates() {
        const { form } = this.props;
        const dispatchDates = form.getState('DispatchDates') || [];
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Dispatch Dates</h4>
                </Grid>

                {dispatchDates.map((dispatchDate, index) => this.renderDispatchDate(dispatchDate, index))}

                <Grid item>
                    <Button variant="primary" onClick={() => this.handleAddDispatchDate()}>
                        + Add Dispatch
                    </Button>
                </Grid>

            </Grid>
        );
    }

    renderDispatchDate(dispatchDate, index) {
        const { form } = this.props;
        const staffField = `DispatchDates[${index}].AssignedStaff`;
        const assignedStaff = form.getField(staffField);
        return (
            <Grid key={index} itemBucket>
                <Grid variant="justify" item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings}>
                        <h5>Dispatch {numberToWords(index + 1)}</h5>
                        <IconButton
                            className="icon"
                            title={'Delete'}
                            onClick={() => this.handleShowDeleteDispatchDateModal(dispatchDate)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Inline>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Date" type="date" name={`DispatchDates[${index}].Date`} form={form} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Time" type="time" name={`DispatchDates[${index}].Time`} form={form} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Label htmlFor={`text-DispatchDates[${index}].ContainmentStyle`}>Containment Style On
                        Dispatch</Label><br />
                    <TextField
                        placeholder="Enter containment style..."
                        name={`DispatchDates[${index}].ContainmentStyle`}
                        form={form}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Label htmlFor={`text-DispatchDates[${index}].Ashes`}>Ashes</Label><br />
                    <TextField
                        placeholder="Enter ashes portion"
                        name={`DispatchDates[${index}].Ashes`}
                        form={form}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Select
                        label="Dispatch Method"
                        options={DispatchMethodOptions}
                        name={`DispatchDates[${index}].DispatchMethod`}
                        form={form}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Select
                        label="Ashes collected by"
                        options={AshesCollectedByOptions}
                        name={`DispatchDates[${index}].AshesCollectedBy`}
                        form={form}
                    />
                </Grid>

                {this.renderDispatchDateDestinationAddressField(index)}

                <Grid item xs={12} sm={6}>
                    <Label htmlFor={`text-DispatchDates[${index}].FuneralDirector`}>Funeral Director</Label><br />
                    <TextField
                        placeholder="Enter name of Funeral Director..."
                        name={`DispatchDates[${index}].FuneralDirector`}
                        form={form}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Label htmlFor={staffField + '-input'}>Assigned Staff</Label><br />
                    <StaffAutoComplete
                        placeholder="Search for a staff name here"
                        selectProps={{ multiple: true }}
                        name={staffField}
                        onSelect={(_, person) => this.handleSelectStaff(staffField, person)}
                    />
                    {assignedStaff && <div style={{ marginTop: 6 }}>
                        {assignedStaff.map(person =>
                            <UserCircle
                                key={person.ID}
                                person={person}
                                onDelete={() => this.handleDeleteStaff(staffField, person)}
                            />
                        )}
                    </div>}
                </Grid>

                <Grid item>
                    <TextField
                        label="Comment"
                        name={`DispatchDates[${index}].Comment`}
                        form={form}
                        multiline
                        button={{
                            variant: 'secondary',
                            children: '+ Add Comments'
                        }}
                    />
                </Grid>

                <Grid item>
                    <Checkbox
                        label={`Dispatch ${numberToWords(index + 1)} Complete`}
                        name={`DispatchDates[${index}].DispatchComplete`}
                        form={form}
                    />
                </Grid>
            </Grid>
        );
    }

    renderDispatchDateDestinationAddressField(index) {
        const { form } = this.props;

        const dispatchDates = form.getState('DispatchDates') || [];
        const dispatchDate = dispatchDates[index];

        if (!dispatchDate) {
            return null;
        }

        if (dispatchDate.DispatchMethod !== 'Delivered to') {
            return null;
        }

        const dispatchDateDestinationAddressComponentFields = createAddressComponentFields(
            'DispatchDates[' + index + '].DestinationAddress'
        );

        return (
            <Grid item>
                <AddressAutocomplete
                    allowCustomAddress
                    label="Destination Address"
                    form={form}
                    componentFields={dispatchDateDestinationAddressComponentFields}
                />
            </Grid>
        );
    }

    renderReflectionRoomRequired(readOnly) {
        const { form } = this.props;
        return (
            <Fragment>
                <Grid item xs={12} sm={6}>
                    {!readOnly ? (
                        <RadioGroup
                            label="Reflection Room"
                            options={[
                                { label: 'Yes', value: 'Required' },
                                { label: 'No', value: 'NotRequired' },
                                { label: 'Unsure', value: '' }
                            ]}
                            name="ReflectionRoomRequired"
                            disabled={readOnly}
                            form={form}
                        />
                    ) : (
                        <ReadonlyField label="Reflection Room">
                            {'Required' === form.getField('ReflectionRoomRequired') ? 'Yes' : 'No'}
                        </ReadonlyField>
                    )}
                </Grid>
            </Fragment>
        );
    }

    renderReflectionRoom(readOnly) {
        const { form } = this.props;
        const showBookingTable = form.getField('ReflectionRoomRequired') === 'Required';
        if (!showBookingTable) return null;

        const ReflectionRoom = form.getField('ReflectionRoom');
        const HasReflectionRoom = ReflectionRoom && ReflectionRoom.Date;
        const formattedNames =
            HasReflectionRoom && ReflectionRoom.AttendeeNames && ReflectionRoom.AttendeeNames.replace(/\n/g, ', ');

        const dateStamp = moment(form.getField('CremationDate') || '').format('YYYYMMDD');

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Reflection Room Booking</h4>
                </Grid>

                {showBookingTable && (
                    <Fragment>
                        {HasReflectionRoom && (
                            <Grid item>
                                <Table>
                                    <HeaderRow pad>
                                        <Cell colSpan={1}>Date</Cell>
                                        <Cell colSpan={1}>Time</Cell>
                                        <Cell colSpan={1}>Attendees</Cell>
                                        {(!readOnly) && (
                                            <>
                                                <Cell colSpan={1}>Edit</Cell>
                                                <Cell colSpan={1}>Delete</Cell>
                                            </>
                                        )}
                                    </HeaderRow>
                                    <Row pad>
                                        <Cell dataLabel="Date">
                                            {niceDateFromString(ReflectionRoom.Date)}
                                        </Cell>
                                        <Cell dataLabel="Time">
                                            {niceTimeFromString(ReflectionRoom.Start)}
                                            {' to '}
                                            {niceTimeFromString(ReflectionRoom.End)}
                                        </Cell>
                                        <Cell dataLabel="Attendees">{formattedNames}</Cell>
                                        {(!readOnly) && (
                                            <>
                                                <Cell dataLabel="Edit">
                                                    <IconButton
                                                        className="icon"
                                                        onClick={() => this.handleShowReflectionRoomBookingModal()}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Cell>
                                                <Cell dataLabel="Delete">
                                                    <IconButton
                                                        onClick={() => !!ReflectionRoom.ID
                                                            ? this.handleShowDeleteReflectionRoomBookingAlertModal()
                                                            : this.handleDeleteReflectionRoomBooking()
                                                        }
                                                        className="icon"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Cell>
                                            </>
                                        )}
                                    </Row>
                                </Table>
                            </Grid>
                        )}
                        <Grid item>
                            <Inline>
                                {!HasReflectionRoom && (
                                    <Button
                                        variant="primary"
                                        onClick={() => this.handleShowReflectionRoomBookingModal()}
                                    >
                                        Add Room Booking...
                                    </Button>
                                )}
                                <Button variant="secondary" href={'/calendar/' + dateStamp} target="_blank">
                                    View Calendar
                                </Button>
                            </Inline>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderWitnessOfInsertionRequired(readOnly) {
        const { form } = this.props;
        return (
            <Fragment>
                <Grid item xs={12} sm={6}>
                    {!readOnly ? (
                        <RadioGroup
                            label="Witness of Insertion"
                            options={[
                                { label: 'Yes', value: 'Required' },
                                { label: 'No', value: 'NotRequired' },
                                { label: 'Unsure', value: '' }
                            ]}
                            name="WitnessOfInsertionRequired"
                            disabled={readOnly}
                            form={form}
                        />
                    ) : (
                        <ReadonlyField label="Witness of Insertion">
                            {'Required' === form.getField('WitnessOfInsertionRequired') ? 'Yes' : 'No'}
                        </ReadonlyField>
                    )}
                </Grid>
            </Fragment>
        );
    }


    handleDeleteReflectionRoomBooking() {
        const { form } = this.props;

        form.setState({ ReflectionRoom: null });
    }

    handleShowReflectionRoomBookingModal() {
        this.setState({ showReflectionRoomBookingModal: true });
    }

    handleCloseReflectionRoomBookingModal(newItem) {
        const { form } = this.props;
        this.setState({ showReflectionRoomBookingModal: false });
        if (newItem) form.setField({ ReflectionRoom: newItem });
    }

    handleShowDeleteReflectionRoomBookingAlertModal() {
        this.setState({ showDeleteReflectionRoomBookingAlertModal: true });
    }

    handleCloseDeleteReflectionRoomBookingAlertModal() {
        this.setState({ showDeleteReflectionRoomBookingAlertModal: false });
    }

    handleShowAshLocationHistoryModal(index) {
        this.setState({ editAshLocationHistoryIndex: index });
        this.setState({ showAshLocationHistoryModal: true });
    }

    handleCloseAshLocationHistoryModal() {
        this.setState({ showAshLocationHistoryModal: false });
        this.setState({ editAshLocationHistoryIndex: null });
    }

    handleSaveAshLocationHistoryModal(ashLocation) {
        const { form } = this.props;
        const { editAshLocationHistoryIndex } = this.state;
        const AshLocations = form.getState('AshLocations') || [];

        if (
            editAshLocationHistoryIndex !== null &&
            editAshLocationHistoryIndex !== undefined &&
            AshLocations[editAshLocationHistoryIndex]
        ) {
            const existingAshLocationHistory = AshLocations[editAshLocationHistoryIndex];
            Object.assign(existingAshLocationHistory, ashLocation);
        } else {
            AshLocations.push(ashLocation);
        }

        this.setState({ showAshLocationHistoryModal: false });
        this.setState({ editAshLocationHistoryIndex: null });

        form.setState({ AshLocations });
        form.save();
    }

    handleDeleteAshLocationHistory(index) {
        const { form } = this.props;
        const AshLocations = form.getState('AshLocations') || [];

        if (index !== -1) {
            AshLocations.splice(index, 1);
            form.setState({ AshLocations });
        }
    }

    handleShowSpecialInstructionModal(index) {
        this.setState({ editSpecialInstructionIndex: index });
        this.setState({ showSpecialInstructionModal: true });
    }

    handleCloseSpecialInstructionModal() {
        this.setState({ showSpecialInstructionModal: false });
        this.setState({ editSpecialInstructionIndex: null });
    }

    handleSaveSpecialInstructionModal(specialInstruction) {
        const { form } = this.props;
        const { editSpecialInstructionIndex } = this.state;
        const SpecialInstructions = form.getState('SpecialInstructions') || [];

        if (
            editSpecialInstructionIndex !== null &&
            editSpecialInstructionIndex !== undefined &&
            SpecialInstructions[editSpecialInstructionIndex]
        ) {
            const existingSpecialInstruction = SpecialInstructions[editSpecialInstructionIndex];
            Object.assign(existingSpecialInstruction, specialInstruction);
        } else {
            SpecialInstructions.push(specialInstruction);
        }

        this.setState({ showSpecialInstructionModal: false });
        this.setState({ editSpecialInstructionIndex: null });

        form.setState({ SpecialInstructions });
    }

    handleDeleteSpecialInstruction(index) {
        const { form } = this.props;
        const SpecialInstructions = form.getState('SpecialInstructions') || [];

        if (index !== -1) {
            SpecialInstructions.splice(index, 1);
            form.setState({ SpecialInstructions });
        }
    }

    handleAddDispatchDate() {
        const { form } = this.props;
        const DispatchDates = form.getState('DispatchDates') || [];
        DispatchDates.push({});
        form.setState({ DispatchDates });
    }

    handleShowDeleteDispatchDateModal(dispatchDate) {
        this.setState({ dispatchDateToDelete: dispatchDate });
        this.setState({ showDeleteDispatchDateAlertModal: true });
    }

    handleCloseDeleteDispatchDateModal() {
        this.setState({ dispatchDateToDelete: null });
        this.setState({ showDeleteDispatchDateAlertModal: false });
    }

    handleDeleteDispatchDate() {
        const { form } = this.props;
        const DispatchDates = form.getState('DispatchDates') || [];

        let dispatchDate = this.state.dispatchDateToDelete;

        let dispatchDateIndex = DispatchDates.indexOf(dispatchDate);
        if (dispatchDateIndex !== -1) {
            DispatchDates.splice(dispatchDateIndex, 1);
            form.setState({ DispatchDates });
        }

        this.setState({ dispatchDateToDelete: null });
        this.setState({ showDeleteDispatchDateAlertModal: false });
    }

    handleSelectStaff(propertyName, staff) {
        const { form } = this.props;
        if (!!staff) {
            delete staff['__typename'];
            const staffList = form.getState(propertyName) || [];
            const idx = staffList.findIndex(person => person.ID === staff.ID);
            if (idx < 0) {
                staffList.push(staff);
                form.setState({ [propertyName]: staffList });
            }
        }
    }

    handleDeleteStaff(propertyName, staff) {
        const { form } = this.props;
        const staffList = form.getState(propertyName) || [];
        const idx = staffList.findIndex(person => person.ID === staff.ID);
        if (idx < 0) {
            // huh?
        } else {
            staffList.splice(idx, 1);
            form.setState({ [propertyName]: staffList });
        }
    }

    handleShowDeleteSpecialInstructionAlertModal(index) {
        this.setState({ showDeleteInstructionAlertModal: true });
    }

    handleCloseDeleteSpecialInstructionAlertModal() {
        this.setState({ showDeleteInstructionAlertModal: false });
    }

    handleShowDeleteAshLocationAlertModal() {
        this.setState({ showDeleteAshLocationAlertModal: true });
    }

    handleCloseDeleteAshLocationAlertModal() {
        this.setState({ showDeleteAshLocationAlertModal: false });
    }
}

export default {
    id: 'RecordDetails',
    label: 'Record Details',
    component: withStyles({})(RecordDetailsTab),
    fragment: CremationOrderFragment,
    validation: {
        required: ['Deceased.FirstName']
    },
    onLoad: data => {
        if (data.ReflectionRoomRequired && data.ReflectionRoomRequired !== 'Required' && !data.ReflectionRoom) {
            data.ReflectionRoom = { ID: 0 };
        }
    },
    formatSaveData: (saveData, state) => {
        if (saveData.IsDeliveryOnly) {
            saveData.ReflectionRoomRequired = null;
            saveData.WitnessOfInsertionRequired = null;
            saveData.SameDayCremation = null;
        }
        if (state.ReflectionRoomRequired !== 'Required' && state.ReflectionRoom) {
            delete saveData.ReflectionRoom;
            delete state.ReflectionRoom;
        }
        if (saveData.DispatchDates) {
            saveData.DispatchDates = state.DispatchDates.map(obj => ({
                ...obj,
                AssignedStaff: obj.AssignedStaff ? obj.AssignedStaff.map(staff => ({ ID: staff.ID })) : []
            }));
        }
    }
};
