import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ColumnLayout from '../../components/layout/ColumnLayout';
import CremationOrderFragment from '../../fragments/CremationOrder';
import { getUser } from '../../utils/sessions';
import CommunicationNoteModal from '../CommunicationNotes/CommunicationNoteModal';
import { getOffice } from '../../environment';

class CorrespondenceTab extends Component {
    render() {
        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </ColumnLayout>
            </Fragment>
        );
    }

    renderLeftColumn() {
        return <Fragment>{this.renderCommunicationNotes()}</Fragment>;
    }

    renderRightColumn() {
        return <Fragment />;
    }

    renderCommunicationNotes() {
        const { form } = this.props;
        const user = getUser();
        const office = getOffice();
        return <CommunicationNoteModal
            orderID={form.getField('ID')}
            orderKey={form.getField('LegacyKey')}
            orderType="CremationOrder"
            orderClient={form.getField('Client')}
            orderAuthorisedContacts={form.getField('AuthorisedContacts')}
            substitutionFields={{
                '[[Client.FirstName]]': form.getField('Client.FirstName'),
                '[[Client.Surname]]': form.getField('Client.Surname'),
                '[[Deceased.FirstName]]': form.getField('Deceased.FirstName'),
                '[[Deceased.Surname]]': form.getField('Deceased.Surname'),
                '[[Reference]]': form.getField('LegacyKey'),
                '[[CremationNumber]]': form.getField('CremationNumber'),
                '[[StaffName]]': user.FirstName + ' ' + user.Surname,
                '[[OfficeName]]': office && office.Title,
                '[[OfficePhone]]': office && office.Phone
            }}
        />;
    }

}

export default {
    id: 'Correspondence',
    label: 'Correspondence',
    component: withStyles({})(CorrespondenceTab),
    fragment: CremationOrderFragment
};
