import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { joinDefined, prettyNumber, stringIsNullOrEmpty } from '../../utils/strings';
import Table, { Cell, HeaderRow, Row } from './Table';
import Inline, { inlineAlignment } from '../layout/Inline';
import { niceDateFromString, niceDateTimeFromString, niceTimeFromString } from '../../utils/date';
import { getBrandHomeByKey } from '../../utils/brands';
import AlertBar from '../form/AlertBar';
import Button from '../form/Button';

export default withStyles({})(({ variant, runsheetType, runsheetStatus, cremation, children }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `runsheet--${variant}`;

    const deceased = cremation.Deceased || false;
    const deceasedName = !!deceased && joinDefined([deceased.FirstName, deceased.MiddleName, deceased.Surname], ' ');
    const recordID = cremation.ID;
    const legacyKey = cremation.LegacyKey;
    const funeralBrand = getBrandHomeByKey(legacyKey).label; // todo
    const assignedStaff = joinDefined(
        [cremation.CremationOperator.FirstName, cremation.CremationOperator.Surname],
        ' '
    );
    const witnessInsertion = 'Required' === cremation.WitnessOfInsertionRequired;
    const funeralService = cremation.FuneralService || cremation.Funeral.PlaceOfService.Type;
    const cremationDateTime = niceDateTimeFromString(joinDefined([
        (cremation.DeliveryDate || cremation.EstimatedDeliveryDate || cremation.CremationDate),
        (cremation.DeliveryTime || cremation.EstimatedDeliveryTime || cremation.CremationTime)
    ], ' '));
    const deceasedWeight = cremation.DeceasedWeight;
    const estimatedDeceasedWeight = cremation.EstimatedDeceasedWeight;
    const sameDay = !!cremation.SameDayCremation;
    const reflectionRoomAttendees = joinDefined(
        [
            !!cremation.ReflectionRoom.Applicant ? 'Applicant' : null,
            !!cremation.ReflectionRoom.AuthorisedContact ? 'Authorised Contact' : null,
            cremation.ReflectionRoom.AttendeeNames
        ],
        ', '
    );

    const renderReflectionRoomDetails = () => {
        return (
            !!parseInt(cremation.ReflectionRoom.ID) && (
                <Row pad>
                    <Cell colSpan={2}>
                        <h5>Reflection Room Booking:</h5>
                        <p>
                            <span className="info">When:&nbsp;</span>
                            {niceDateFromString(cremation.ReflectionRoom.Start)}
                            <span> from </span>
                            {(niceTimeFromString(cremation.ReflectionRoom.Start) || '(not set)') +
                                ' to ' +
                                (niceTimeFromString(cremation.ReflectionRoom.End) || '(not set)')}
                        </p>
                        {!!reflectionRoomAttendees && (
                            <p>
                                <span className="info">Attendees:&nbsp;</span>
                                {reflectionRoomAttendees}
                            </p>
                        )}
                    </Cell>
                </Row>
            )
        );
    };

    return (
        <div className={`runsheet ${cssVariant || ''}`}>
            <Table>
                <HeaderRow pad>
                    <Cell colSpan={2}>
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <div>
                                <span className="runsheet-header">
                                    {runsheetStatus} | {runsheetType}:&nbsp;
                                    {deceasedName}
                                </span>
                            </div>
                            <p className="header-record">
                                <Button variant="link-white" href={'/cremations/' + recordID}>
                                    {legacyKey}
                                </Button>
                                {!!funeralBrand && ' | ' + funeralBrand}
                            </p>
                        </Inline>
                    </Cell>
                </HeaderRow>
                <Row pad>
                    <Cell>
                        <h5>Delivery</h5>
                        <p>
                            <span className="info">{!!cremation.DeliveryDate ? 'Arrived' : 'Due'}:&nbsp;</span>
                            {cremationDateTime}
                        </p>

                        {cremation.IsDeliveryOnly ? (
                            <p>
                                <span className="info">Delivery only</span>
                            </p>
                        ) : (!!funeralService &&
                            <p>
                                <span className="info">Funeral service:&nbsp;</span>
                                {'Service' === funeralService ? ' Full Service' : funeralService}
                            </p>
                        )}
                        {!!deceasedWeight
                            ? (
                                <p>
                                    <span className="info">Deceased Weight:&nbsp;</span>
                                    {prettyNumber(deceasedWeight)} Kg
                                </p>
                            ) : (!!estimatedDeceasedWeight && (
                                <p>
                                    <span className="info">Estimated Deceased Weight:&nbsp;</span>
                                    {prettyNumber(estimatedDeceasedWeight)} Kg
                                </p>
                            ))
                        }

                        <p>
                            <span className="info">Delivered by:&nbsp;</span>
                            {cremation.DeliveredBy || '(Unsure)'}
                        </p>

                        {!!children.length && (
                            <Fragment>
                                <h5>Special Instructions:&nbsp;</h5>
                                {children}
                            </Fragment>
                        )}
                    </Cell>

                    <Cell>
                        <h5>Action</h5>
                        <p>
                            <span className="info">Assigned Staff:&nbsp;</span>
                            {assignedStaff || '(none)'}
                        </p>

                        <p>
                            <span className="info">Insertion scheduled:&nbsp;</span>
                            {niceDateFromString(cremation.EstimatedInsertionDate || cremation.CremationDate)}
                        </p>

                        {!!sameDay && (
                            <AlertBar variant="attention">
                                SAME-DAY CREMATION
                            </AlertBar>
                        )}
                        {!!witnessInsertion && (
                            <AlertBar variant="warning">
                                WITNESS OF INSERTION
                            </AlertBar>
                        )}
                        {!cremation.ReadyForCremation ? (
                            <AlertBar variant="error">
                                This record has not been marked as ready for cremation.
                            </AlertBar>
                        ) : (!!cremation.HasMissingCertificates && (
                            <AlertBar variant="error">
                                This record has been marked as ready for cremation, but has incomplete documentation.
                            </AlertBar>
                        ))}

                        {cremation.DispatchDates.map((dispatchDate, i) => {
                            return (
                                <Fragment key={dispatchDate.ID}>
                                    {!!i && <hr />}
                                    <p>
                                        <span className="info">Dispatch #{i + 1}</span>
                                    </p>
                                    <p>
                                        <span className="info">Containment Style:&nbsp;</span>
                                        {dispatchDate.ContainmentStyle}
                                    </p>
                                    <p>
                                        <span className="info">Dispatch Date:&nbsp;</span>
                                        {niceDateFromString(dispatchDate.Date)}
                                        {!!dispatchDate.Time && ' at ' + niceTimeFromString(dispatchDate.Time)}
                                    </p>
                                    <p>
                                        <span className="info">Comments:&nbsp;</span>
                                        {dispatchDate.Comment}
                                    </p>
                                </Fragment>
                            );
                        })}

                    </Cell>
                </Row>
                {renderReflectionRoomDetails()}
            </Table>

        </div>
    );
});
