import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import Grid from '../../components/layout/Grid';
import Separator from '../../components/layout/Separator';
import Typography from '../../components/form/Typography';
import Table, { Cell, HeaderRow, HeaderRowNested, Row, RowNested } from '../../components/table/Table';
import TableKey from '../../components/table/TableKey';
import Inline from '../../components/layout/Inline';
import DataTable from '../../components/dataTable/DataTable';
import { joinDefined } from '../../utils/strings';
import { isNullOrUndefined } from '../../utils/objects';
import { BRANDS } from '../../utils/brands';
import Button from '../../components/form/Button';
import AttentionDot from '../../components/table/AttentionDot';

const memberColumns = [
    {
        label: 'ID',
        propertyPath: 'ID',
        renderCell: (row, value) => <span>#{value}</span>
    },
    {
        label: 'Name',
        renderCell: row => <span>{joinDefined([row.Surname, row.FirstName, row.Middlename], ' ')}</span>
    },
    {
        label: 'Email',
        propertyPath: 'Email'
    }
];

const memberFragment = gql`
    fragment StylingMember on Member {
        ID
        FirstName
        MiddleName
        Surname
        Email
    }
`;

const cremationColumns = [
    {
        label: 'ID',
        propertyPath: 'LegacyKey',
        renderCell: (row, value) => (
            <Button href={'/cremation/' + row.ID} variant="link-orange">
                {value}
            </Button>
        )
    },
    {
        label: 'Deceased',
        renderCell: row => (
            <span>{joinDefined([row.Deceased.Surname, row.Deceased.FirstName, row.Deceased.MiddleName], ' ')}</span>
        )
    },
    {
        label: 'Authorised Contact',
        renderCell: row => {
            let firstAuthContact = row.AuthorisedContacts[0];

            if (isNullOrUndefined(firstAuthContact)) {
                return null;
            }
            return (
                <span>
                    {joinDefined(
                        [firstAuthContact.Surname, firstAuthContact.FirstName, firstAuthContact.MiddleName],
                        ' '
                    )}
                </span>
            );
        }
    },
    {
        label: 'Applicant',
        renderCell: row => (
            <span>{joinDefined([row.Client.Surname, row.Client.FirstName, row.Client.MiddleName], ' ')}</span>
        )
    },
    {
        label: 'Containment ',
        propertyPath: 'ContainmentStyle'
    },
    {
        label: 'Cremation date',
        propertyPath: 'CremationDate',
        renderCell: row => <span>{joinDefined([row.CremationDate, row.CremationTime], ' ')}</span>
    },
    {
        label: 'Dispatch date',
        renderCell: row => {
            let dispatchDate = row.DispatchDates[0];

            if (isNullOrUndefined(dispatchDate)) {
                return null;
            }
            return <span>{joinDefined([dispatchDate.Date, dispatchDate.Time], ' ')}</span>;
        }
    },
    {
        label: 'Dispatch method',
        renderCell: row => {
            let dispatchDate = row.DispatchDates[0];

            if (isNullOrUndefined(dispatchDate)) {
                return null;
            }
            return <span>{joinDefined([dispatchDate.DispatchMethod, dispatchDate.AshesCollectedBy], ': ')}</span>;
        }
    }
];

const cremationFragment = gql`
    fragment StylingCremationOrder on CremationOrder {
        ID
        LegacyKey
        ContainmentStyle
        CremationDate
        CremationTime
        DispatchDates {
            ID
            Date
            Time
            DispatchMethod
            AshesCollectedBy
        }
        Deceased {
            ID
            FirstName
            MiddleName
            Surname
        }
        Client {
            ID
            FirstName
            MiddleName
            Surname
        }
        AuthorisedContacts {
            ID
            FirstName
            MiddleName
            Surname
        }
    }
`;

class TableStyling extends Component {
    render() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Separator />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4">Table</Typography>
                </Grid>
                <Grid item xs={12}>
                    {this.renderTable()}
                </Grid>

                <Grid item xs={12}>
                    <Separator />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4">Table - Branded</Typography>
                </Grid>

                {this.renderTableBrandedKey()}

                <Grid item xs={12}>
                    {this.renderTableBranded()}
                </Grid>

                <Grid item xs={12}>
                    <Separator />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4">Data Table</Typography>
                </Grid>
                <Grid item xs={12}>
                    {this.renderDataTable()}
                </Grid>

                <Grid item xs={12}>
                    <Separator />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4">Branded Data Table</Typography>
                </Grid>
                <Grid item xs={12}>
                    {this.renderDataTableBranded()}
                </Grid>
            </Grid>
        );
    }

    renderTable() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={12} md={6}>
                    <Table columns={[{ label: 'Header 1' }, { label: 'Header 2' }]} columnspans={[2, 2]}>
                        <HeaderRowNested pad>
                            <Cell>Nested Header 1</Cell>
                            <Cell>Nested Header 2</Cell>
                            <Cell>Nested Header 3</Cell>
                            <Cell>Nested Header 4</Cell>
                        </HeaderRowNested>
                        <Row pad>
                            <Cell>Cell 1</Cell>
                            <Cell>Cell 2</Cell>
                            <Cell>Cell 3</Cell>
                            <Cell>Cell 4</Cell>
                        </Row>
                        <Row pad>
                            <Cell>Cell 1</Cell>
                            <Cell>Cell 2</Cell>
                            <Cell>Cell 3</Cell>
                            <Cell>Cell 4</Cell>
                        </Row>
                        <Row pad>
                            <Cell>Cell 1</Cell>
                            <Cell>Cell 2</Cell>
                            <Cell>Cell 3</Cell>
                            <Cell>Cell 4</Cell>
                        </Row>
                        <RowNested pad>
                            <Cell>Alt Cell 1</Cell>
                            <Cell>Alt Cell 2</Cell>
                            <Cell>Alt Cell 3</Cell>
                            <Cell>Alt Cell 4</Cell>
                        </RowNested>
                        <RowNested pad>
                            <Cell>Alt Cell 1</Cell>
                            <Cell>Alt Cell 2</Cell>
                            <Cell>Alt Cell 3</Cell>
                            <Cell>Alt Cell 4</Cell>
                        </RowNested>
                        <RowNested pad>
                            <Cell>Alt Cell 1</Cell>
                            <Cell>Alt Cell 2</Cell>
                            <Cell>Alt Cell 3</Cell>
                            <Cell>Alt Cell 4</Cell>
                        </RowNested>
                        <RowNested pad>
                            <Cell>Alt Cell 1</Cell>
                            <Cell>Alt Cell 2</Cell>
                            <Cell>Alt Cell 3</Cell>
                            <Cell>Alt Cell 4</Cell>
                        </RowNested>
                    </Table>
                </Grid>
            </Grid>
        );
    }

    renderTableBrandedKey() {
        return (
            <Grid item>
                <Inline center>
                    <Typography variant="h4">Key:</Typography>
                    {BRANDS.map(brand => (
                        <TableKey key={brand.letterCode} variant={brand.style} label={brand.label} />
                    ))}
                    <AttentionDot label="Attention Required" />
                </Inline>
            </Grid>
        );
    }
    renderTableBranded() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={12} md={6}>
                    <Table>
                        <HeaderRow pad>
                            <Cell colSpan={3}>Header 1</Cell>
                        </HeaderRow>
                        <Row pad>
                            <Cell>Normal Table</Cell>
                            <Cell>Cell 1</Cell>
                            <Cell>Cell 1</Cell>
                        </Row>
                        <Row pad variant="tablestyle1">
                            <Cell>Stan Crapp brand</Cell>
                            <Cell>variant = tablestyle1</Cell>
                            <Cell>Cell 1</Cell>
                        </Row>
                        <Row pad variant="tablestyle2">
                            <Cell>H Parsons brand</Cell>
                            <Cell>variant = tablestyle2</Cell>
                            <Cell>Cell 1</Cell>
                        </Row>
                        <Row pad variant="tablestyle3">
                            <Cell>Rankins brand</Cell>
                            <Cell>variant = tablestyle3</Cell>
                            <Cell>Cell 1</Cell>
                        </Row>
                        <Row pad variant="tablestyle4">
                            <Cell>City Funerals brand</Cell>
                            <Cell>variant = tablestyle4</Cell>
                            <Cell>Cell 1</Cell>
                        </Row>
                        <Row pad variant="tablestyle5">
                            <Cell>Mountain View brand</Cell>
                            <Cell>variant = tablestyle5</Cell>
                            <Cell>Cell 1</Cell>
                        </Row>
                        <Row pad variant="tablestyle6">
                            <Cell>Tender Funerals brand</Cell>
                            <Cell>variant = tablestyle6</Cell>
                            <Cell>Cell 1</Cell>
                        </Row>
                        <Row pad variant="tablestyle7">
                            <Cell>Easy Funerals brand</Cell>
                            <Cell>variant = tablestyle7</Cell>
                            <Cell>Cell 1</Cell>
                        </Row>
                    </Table>
                </Grid>
            </Grid>
        );
    }

    renderDataTable() {
        return (
            <Fragment>
                <Grid item>
                    <Grid container>
                        <Grid item>
                            <DataTable
                                dataObject="Member"
                                queryName="readMembers"
                                fragment={memberFragment}
                                columns={memberColumns}
                                onClickRow={() => alert('You clicked on a member')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderDataTableBranded() {
        return (
            <Fragment>
                <Grid item>
                    <Grid container>
                        <Grid item>
                            <DataTable
                                dataObject="CremationOrder"
                                queryName="readCremationOrders"
                                fragment={cremationFragment}
                                columns={cremationColumns}
                                isFilterable
                                isSortable
                                variables={{ limit: 6, filterBy: [] }}
                                brandProperty="LegacyKey" //the property to determine the brand from
                                searchVariable="contains"
                                tableTitle="Cremation Records"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

export default withStyles({})(TableStyling);
