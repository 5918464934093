import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../components/layout/Grid';
import Modal from '../../../components/modal/Modal';
import Typography from '../../../components/form/Typography';
import TextField from '../../../components/form/TextField';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import TimeSelect from '../../../components/form/TimeSelect';
import Checkbox from '../../../components/form/Checkbox';
import Button from '../../../components/form/Button';
import { adjustTimeString, dateTimeToString } from '../../../utils/date';
import TickCircleIcon from '../../../components/icon/TickCircleIcon';
import createForm from '../../../utils/createForm';
import { CloseIcon } from '../../../components/IconIndex';

class ReflectionRoomBookingModal extends Component {
    state = {
        opened: false,
        form: createForm(this, {})
    };

    static getDerivedStateFromProps({ data, open }, { opened, form }) {
        if (!!open && !opened) {
            const newForm = createForm(form.context, { ...data });
            return { form: newForm, opened: true };
        }
        return null;
    }

    render() {
        const { open, onClose } = this.props;
        const { form } = this.state;
        return (
            <Modal open={open} variant="simple" onClose={() => onClose()} canClickOut={false}>
                <Grid container>
                    <Grid item>
                        <Typography variant="h2" weight="medium">
                            Reflection Room booking
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container bucket>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h4">Date & Time</Typography>
                        <Inline>
                            <Checkbox label="Confirmed" form={form} name="Confirmed" />
                        </Inline>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField label="Date" type="date" form={form} name="Date" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField label="Start Time" type="time" form={form} name="Time" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TimeSelect label="Duration (Hours)" form={form} name="Duration" useNumbers />
                    </Grid>
                </Grid>
                <Grid container bucket>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h4">Attendees</Typography>
                        <Inline>
                            <Checkbox label="Applicant" form={form} name="Applicant" />
                            <Checkbox label="Authorised Contact" form={form} name="AuthorisedContact" />
                        </Inline>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField type="number" form={form} name="NumberOfPeople" label="Total attendees"
                                   InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField label="Names of Additional Attendees" multiline form={form} name="AttendeeNames"
                                   InputLabelProps={{ shrink: true }}
                                   placeholder={'Enter any names, if known...'}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <Inline alignment={inlineAlignment.rightAlignSiblings}>
                            <div>
                                <Button variant="secondary" onClick={() => this.closeMe()} startIcon={<CloseIcon />}>
                                    Close
                                </Button>
                            </div>
                            <Button variant="primary" onClick={() => this.closeMe({ ...form.state })}
                                    startIcon={<TickCircleIcon />}>
                                {form.getField('ID') ? 'Update' : 'Add'} Booking
                            </Button>
                        </Inline>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    closeMe = data => {
        const { onClose } = this.props;
        if (onClose) {
            if (data) {
                data.Start = dateTimeToString(data.Date + ' ' + data.Time);
                data.End = dateTimeToString(data.Date + ' ' + adjustTimeString(data.Time, data.Duration * 60));
            }
            onClose(data);
        }
        this.setState({ opened: false });
    };
}

export default withStyles({})(ReflectionRoomBookingModal);
