import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ColumnLayout from '../../components/layout/ColumnLayout';
import PlaqueOrder from '../../fragments/PlaqueOrder';
import { getUser } from '../../utils/sessions';
import CommunicationNoteModal from '../CommunicationNotes/CommunicationNoteModal';
import Grid from '../../components/layout/Grid';
import Button from '../../components/form/Button';
import Table, { Cell, HeaderRow, Row } from '../../components/table/Table';
import { niceDateTimeFromString } from '../../utils/date';
import { joinDefined } from '../../utils/strings';
import { TickIcon } from '../../components/IconIndex';
import SendProofModal from './Modals/SendProofModal';
import EditProofModal from './Modals/EditProofModal';
import { getOffice } from '../../environment';

class CorrespondenceTab extends Component {

    state = {
        showSendProofModal: false,
        proofEmailEditModal: {
            open: false,
            proofEmailId: null
        }
    };

    render() {
        const { showSendProofModal, proofEmailEditModal } = this.state;
        const { form } = this.props;
        const user = getUser();
        const office = getOffice();
        return (
            <Fragment>
                <SendProofModal
                    open={showSendProofModal}
                    plaqueOrderID={form.getField('ID')}
                    plaqueOrderClient={form.state.Client}
                    onClose={() => this.handleCloseSendProofModal()}
                    parentForm={form}
                    substitutionFields={{
                        '[[Client.FirstName]]': form.getField('Client.FirstName'),
                        '[[Client.Surname]]': form.getField('Client.Surname'),
                        '[[Deceased.FirstName]]': form.getField('Deceased.FirstName'),
                        '[[Deceased.Surname]]': form.getField('Deceased.Surname'),
                        '[[Reference]]': form.getField('LegacyKey'),
                        '[[StaffName]]': user.FirstName + ' ' + user.Surname,
                        '[[OfficeName]]': office && office.Title,
                        '[[OfficePhone]]': office && office.Phone
                    }}
                />

                <EditProofModal
                    open={!!proofEmailEditModal.open}
                    plaqueOrderID={form.getField('ID')}
                    plaqueOrderClient={form.state.Client}
                    plaqueOrderProofEmails={form.state.ProofEmails}
                    emailId={proofEmailEditModal.proofEmailId}
                    onClose={() => this.handleCloseEditProofModal()}
                    parentForm={form}
                    substitutionFields={{
                        '[[Client.FirstName]]': form.getField('Client.FirstName'),
                        '[[Client.Surname]]': form.getField('Client.Surname'),
                        '[[Deceased.FirstName]]': form.getField('Deceased.FirstName'),
                        '[[Deceased.Surname]]': form.getField('Deceased.Surname'),
                        '[[Reference]]': form.getField('LegacyKey'),
                        '[[StaffName]]': user.FirstName + ' ' + user.Surname,
                        '[[OfficeName]]': office && office.Title,
                        '[[OfficePhone]]': office && office.Phone
                    }}
                />
                <ColumnLayout>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </ColumnLayout>
            </Fragment>
        );
    }

    renderLeftColumn() {
        return <Fragment>{this.renderCommunicationNotes()}</Fragment>;
    }

    renderRightColumn() {
        return <Fragment>{this.renderEmailProofToClient()}</Fragment>;
    }

    renderCommunicationNotes() {
        const { form } = this.props;
        const user = getUser();
        const office = getOffice();
        return <CommunicationNoteModal
            orderID={form.getField('ID')}
            orderKey={form.getField('LegacyKey')}
            orderType="PlaqueOrder"
            orderClient={form.getField('Client')}
            orderAuthorisedContacts={form.getField('AuthorisedContacts')}
            substitutionFields={{
                '[[Client.FirstName]]': form.getField('Client.FirstName'),
                '[[Client.Surname]]': form.getField('Client.Surname'),
                '[[Deceased.FirstName]]': form.getField('Deceased.FirstName'),
                '[[Deceased.Surname]]': form.getField('Deceased.Surname'),
                '[[Reference]]': form.getField('LegacyKey'),
                '[[StaffName]]': user.FirstName + ' ' + user.Surname,
                '[[OfficeName]]': office && office.Title,
                '[[OfficePhone]]': office && office.Phone
            }}
        />;
    }

    renderEmailProofToClient() {
        return (
            <Grid container bucket>
                {this.renderProofEmailsHistory()}
                <Grid item>
                    <Button variant="primary" onClick={() => this.handleShowSendProofModal()}>
                        Send Proof Email...
                    </Button>
                </Grid>
            </Grid>
        );
    }

    renderProofEmailsHistory() {
        const { form } = this.props;
        let proofEmails = form.getField('ProofEmails');
        const applicant = form.getField('Client');

        proofEmails = proofEmails.filter(obj => !obj.EmailSupplier);

        return (
            <Fragment>
                <Grid item>
                    <h4>Proof Emails</h4>
                </Grid>
                <Grid item>
                    {(proofEmails && proofEmails.length > 0) ? <Table>
                        <HeaderRow pad>
                            <Cell colSpan={1}>ID</Cell>
                            <Cell colSpan={1}>Sent</Cell>
                            <Cell colSpan={1}>Send To</Cell>
                            <Cell colSpan={1}>Approved</Cell>
                        </HeaderRow>
                        {proofEmails.map(proofEmail => (
                            <Row pad key={'email-' + proofEmail.ID}>
                                <Cell dataLabel="Email No.">
                                    <Button
                                        variant="link-orange"
                                        onClick={() => this.handleShowEditProofModal(proofEmail.ID)}
                                    >
                                        #{proofEmail.ID}
                                    </Button>
                                </Cell>
                                <Cell
                                    dataLabel="Sent">{niceDateTimeFromString(proofEmail.EmailSent) || 'Not sent'}</Cell>
                                <Cell dataLabel="Send to">
                                    {joinDefined(
                                        [
                                            proofEmail.EmailApplicant && !!applicant ? applicant.Email : null,
                                            proofEmail.EmailOther ? proofEmail.OtherEmailAddress : null
                                        ],
                                        ', '
                                    )}
                                </Cell>
                                <Cell dataLabel="Approved">
                                    {!!proofEmail.Approved && <TickIcon />}
                                </Cell>
                            </Row>
                        ))}
                    </Table> : <p>There are currently no proof emails.</p>
                    }
                </Grid>
            </Fragment>
        );
    }

    handleShowSendProofModal() {
        this.setState({ showSendProofModal: true });
    }

    handleCloseSendProofModal() {
        this.setState({ showSendProofModal: false });
    }

    handleShowEditProofModal(id) {
        this.setState({
            proofEmailEditModal: {
                open: true,
                proofEmailId: id
            }
        });
    }


    handleCloseEditProofModal() {
        this.setState({
            proofEmailEditModal: {
                open: false,
                proofEmail: null
            }
        });
    }

}

export default {
    id: 'Correspondence',
    label: 'Correspondence',
    component: withStyles({})(CorrespondenceTab),
    fragment: PlaqueOrder
};
