import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const Label = ({ text, children, className, htmlFor }) => (
	<label htmlFor={htmlFor} className={`form-label ${className || ''}`} >
		{children || text}
	</label>
);

export default withStyles({})(Label);
