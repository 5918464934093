import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import cloneDeep from 'lodash.clonedeep';
import Grid from '../../../components/layout/Grid';
import Modal from '../../../components/modal/Modal';
import TextField from '../../../components/form/TextField';
import Checkbox from '../../../components/form/Checkbox';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import Button from '../../../components/form/Button';
import UploadField, { getFileName } from '../../../components/form/UploadField';
import FlexGrid from '../../../components/layout/FlexGrid';
import ThumbnailCard from '../../../components/cards/ThumbnailCard';
import createForm from '../../../utils/createForm';
import { deleteTypeName, diff } from '../../../utils/objects';
import { isRelatedObjectDefined } from '../../../utils/bookable';
import ProofEmail from '../../../fragments/ProofEmail';
import { getClient } from '../../../utils/apollo';
import { BackIcon, CloseIcon, EnvelopeIcon, SaveIcon } from '../../../components/IconIndex';
import NextIcon from '../../../components/icon/NextIcon';
import HtmlEditor from '../../../components/form/HtmlEditor';

class SendProofModal extends Component {
    state = {
        tabIndex: 0,
        loading: false,
        form: null,
        open: false
    };

    componentDidUpdate(_, oldState) {
        const { loading, form } = this.state;
        const { open } = this.props;
        if (open && form === null && !loading) {
            this.createProofModal();
            return;
        }

        if (oldState.form === null && form !== null) form.context = this;
    }

    render() {
        const { open } = this.props;
        const { tabIndex, form } = this.state;
        if (!form) return null;

        return (
            <Modal
                open={open}
                variant="stepped"
                onClose={this.onClose}
                canClickOut={false}
                className="email-modal"
                activeTab={tabIndex}
                steps={[
                    {
                        abbreviation: 'Send Proof',
                        title: 'Send proof email of plaque details to the clients',
                        onChangeTab: this.onChangeTab,
                        content: <div>{this.renderEmailDetails()}</div>,
                        actions: <div>{this.renderActions(0)}</div>
                    },
                    {
                        abbreviation: 'View Attachment',
                        title: 'The proof will be sent as an attachment as below:',
                        onChangeTab: this.onChangeTab,
                        content: <div>{this.renderPreview()}</div>,
                        actions: <div>{this.renderActions(1)}</div>
                    }
                ]}
            />
        );
    }

    createProofModal() {
        const email = {
            Message: null,
            ScheduledToSendEmail: false,
            EmailApplicant: false,
            EmailOther: false,
            OtherEmailAddress: null,
            Approved: false,
            SentToRecipientEmailAddresses: null
        };

        //add form details
        const form = createForm(this, email);
        this.setState({ loading: false, form });
        return null;
    }

    renderEmailDetails() {
        const { plaqueOrderClient, substitutionFields } = this.props;
        const { form } = this.state;

        if (!form) return null;

        const attachments = form.getField('Attachments') || [];

        return (
            <Grid container spacing={16}>
                <Grid item>
                    <h4> Email to </h4>
                    <Checkbox label="Email Applicant" disabled={!plaqueOrderClient} name="EmailApplicant" form={form} />
                    <Checkbox label="Email Other Recipient" name="EmailOther" form={form} />
                </Grid>
                {form.getField('EmailOther') && (
                    <Grid item>
                        <TextField label="Email Recipients" name="OtherEmailAddress" form={form} />
                    </Grid>
                )}

                <Grid item>
                    <h4> Message </h4>
                </Grid>

                <Grid item>
                    <HtmlEditor
                        shortcodes={substitutionFields}
                        placeholder="Enter message here..."
                        name="Message" form={form} />
                </Grid>

                <Grid item>
                    <h4>Attachments</h4>
                </Grid>

                <Grid item>
                    <UploadField
                        folderPath={`/documents/plaque/${form.getField('ID')}`}
                        onComplete={({ uploadFile }) => this.handleUploadAttachment(uploadFile, attachments)}
                    />
                </Grid>

                {!!attachments && !!attachments.length && (
                    <Grid item>
                        <h4> Attached Files </h4>
                        <FlexGrid>
                            {attachments.map(file => (
                                <ThumbnailCard
                                    key={'file-' + file.ID}
                                    fileName={file.Name}
                                    fileLink={file.AbsoluteLink}
                                    fileId={file.ID}
                                    onClickRemoveAction={fileId => this.onAttachmentRemove(fileId)}
                                />
                            ))}
                        </FlexGrid>
                    </Grid>
                )}
            </Grid>
        );
    }

    renderPreview() {
        return (
            <Grid container>
                <Grid item>
                    <iframe
                        src="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                        style={{ width: '100%', height: 510 }}
                        title="invoice-pdf"
                    />
                </Grid>
            </Grid>
        );
    }

    renderActions = tabIdx => {
        const { form } = this.state;
        const readOnly = !!form.getField('EmailSent');
        const isDirty = !!form.getDirty().length;
        return (
            <Inline className="button-alignment" alignment={inlineAlignment.rightAlignSiblings} center>
                <Inline>
                    <Button variant="secondary" onClick={this.onClose} startIcon={<CloseIcon />}>
                        Cancel
                    </Button>
                    {1 === tabIdx &&
                        <Button variant="primary" onClick={() => this.onChangeTab(tabIdx - 1)} startIcon={<BackIcon />}>
                            Back
                        </Button>
                    }
                </Inline>

                {1 === tabIdx
                    ? <Button disabled={!isDirty} variant="confirmation" onClick={this.onSave}
                              startIcon={<SaveIcon />}>
                        Save
                    </Button> : <>
                        <Button disabled={readOnly || !isDirty} variant="confirmation" onClick={this.onSave}
                                startIcon={<SaveIcon />}>
                            {(readOnly || !isDirty) ? 'Saved' : 'Save Draft'}
                        </Button>
                        <Button disabled={readOnly} variant="urgent" onClick={this.onSendEmail}
                                startIcon={<EnvelopeIcon />}>
                            {readOnly ? 'Sent' : 'Send Email'}
                        </Button>
                    </>
                }

                {1 !== tabIdx &&
                    <Button variant="primary" onClick={() => this.onChangeTab(tabIdx + 1)} endIcon={<NextIcon />}>
                        Next
                    </Button>
                }
            </Inline>
        );
    };

    onChangeTab = tabIndex => {
        this.setState({ tabIndex });
    };

    onSendEmail = () => {
        const { form } = this.state;

        this.setState({
            loading: true
        });

        form.state.ScheduledToSendEmail = true;

        this.onSave();
    };

    onSave = () => {
        const { form } = this.state;
        const { parentForm, plaqueOrderID } = this.props;

        let email = form.state;

        this.setState({ loading: true });
        const that = this;

        return createProofEmailFunc(email, plaqueOrderID).then(
            ({ data }) => {
                const ProofEmails = parentForm.getField('ProofEmails');
                const createdEmail = data.createProofEmail;
                ProofEmails.push(cloneDeep(createdEmail));
                parentForm.setField({ ProofEmails }, true);

                that.setState({
                    loading: false,
                    open: false,
                    form: null
                });
                parentForm.save();
                this.onClose();
            },
            e => that.onGqlError('Failed to create proof email.', e)
        );
    };

    onClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
        this.setState({
            tabIndex: 0,
            loading: false,
            form: null,
            open: false
        });
    };

    onAttachmentRemove(fileId) {
        const { form } = this.state;

        let Attachments = form.getField('Attachments');
        Attachments = Attachments.filter(obj => fileId !== obj.ID);

        form.setField({ Attachments });
    }

    handleUploadAttachment(uploadFile, attachments) {
        const { form } = this.state;
        attachments = attachments || [];

        attachments.push({
            ID: uploadFile.ID,
            AbsoluteLink: uploadFile.AbsoluteLink,
            Name: getFileName(uploadFile.FileName)
        });

        form.setField({
            Attachments: attachments
        });
    }
}

const createProofEmailMutation = gql`
    ${ProofEmail}
    mutation CreateProofEmail($input: CreateProofEmailInput!) {
        createProofEmail(input: $input) {
            ...ProofEmail
        }
    }
`;

export const createProofEmailFunc = async (proofEmail, plaqueOrderID) => {
    return await getClient().mutate({
        mutation: createProofEmailMutation,
        variables: {
            input: trimProofEmail(plaqueOrderID, proofEmail)
        }
    });
};

export const trimProofEmail = (plaqueOrderID, newEmail, original) => {
    let email = original
        ? JSON.parse(JSON.stringify(original ? diff({ ...newEmail }, original) : { ...newEmail }))
        : JSON.parse(JSON.stringify({ ...newEmail }));

    email.PlaqueOrderID = plaqueOrderID;

    if (isRelatedObjectDefined(original)) {
        email.ID = original.ID;
    }

    const attachments = [];
    if (!!email.Attachments && email.Attachments.length > 0) {
        email.Attachments.forEach(function(attachment, index, object) {
            if (!!attachment.ID) {
                attachments.push({
                    ID: attachment.ID
                });
            }
        });
        email.Attachments = attachments;
    }

    const evidenceFiles = [];
    if (!!email.EvidenceFiles && email.EvidenceFiles.length > 0) {
        email.EvidenceFiles.forEach(function(attachment, index, object) {
            if (!!attachment.ID) {
                evidenceFiles.push({
                    ID: attachment.ID
                });
            }
        });
        email.EvidenceFiles = evidenceFiles;
    }

    deleteTypeName(email);

    return { ...email };
};

export default withStyles({})(SendProofModal);
