import React, { Fragment } from 'react';
import moment from 'moment';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles/index';
import { compose } from 'react-apollo/index';
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import {
    getAppointmentOptionType,
    getCalendarCategoryByClassName,
    getCalendarCategoryByType
} from './CalendarConstants';
import { withEditAppointment } from '../../context/EditAppointment';
import LinkButton from '../../components/form/Button';
import GatedComponent from '../../components/GatedComponent';
import DeleteIcon from '../../components/icon/DeleteIcon';
import { isRelatedObjectDefined } from '../../utils/bookable';
import { joinDefined } from '../../utils/strings';
import { getClient } from '../../utils/apollo';
import { Grow } from '@material-ui/core';
import Draggable from 'react-draggable';
import { niceTimeFromString } from '../../utils/date';

const deleteAppointment = gql`
    mutation deleteAppointment($IDs: [ID]!) {
        deleteAppointments(ids: $IDs)
    }
`;

const deleteCalendarEvent = gql`
    mutation deleteCalendarEvent($IDs: [ID]!) {
        deleteCalendarEvents(ids: $IDs)
    }
`;

const deleteStaffAllocation = gql`
    mutation deleteStaffAllocation($IDs: [ID]!) {
        deleteStaffAllocations(ids: $IDs)
    }
`;

const DraggableWrapper = ({ children, ...other }) => {
    return <Draggable handle=".drag-handle">{React.cloneElement(children, { ...other })}</Draggable>;
};
const DraggableGrow = ({ children, ...other }) => {
    return (
        <Grow {...other} timeout={0}>
            <DraggableWrapper>{children}</DraggableWrapper>
        </Grow>
    );
};

class ViewEvent extends React.Component {
    state = {
        anchorEl: null,
        anchorLeft: 0,
        anchorTop: 0
    };

    render() {
        const { date, event, classes, view } = this.props;
        const { anchorEl } = this.state;
        const open = !!anchorEl;
        const category =
            view === 'Locations'
                ? getCalendarCategoryByClassName(event.ClassName)
                : getCalendarCategoryByType(event.Type);
        let times = { slot: '', popup: <span>Until {moment(event.end).format('LL')} </span> };
        let isAllDay = this.isAllDay(event);

        if (isAllDay === null) {
            // single day event, with times
            times.slot = times.popup = (
                <span>
                    {moment(event.start).format('H:mm')} - {moment(event.end).format('H:mm')}{' '}
                </span>
            );
        } else if (isAllDay === false) {
            // multi-day event, with times
            if (moment(event.start).isSame(date, 'day'))
                times.slot = <span>From {moment(event.start).format('H:mm')} </span>;
            else if (moment(event.end).isSame(date, 'day'))
                times.slot = <span>Until {moment(event.end).format('H:mm')} </span>;
            times.popup = (
                <span>
                    {moment(event.start).format('LT')} - {moment(event.end).format('LT L')}{' '}
                </span>
            );
        } else {
            // all-day event, could be multi-day
            if (
                moment(event.start).isSame(event.end, 'day') ||
                moment(event.start).isSame(
                    moment(event.end)
                        .subtract(1, 'day')
                        .startOf('day')
                )
            ) {
                times.popup = <span>All Day </span>;
            }
        }

        const recordType = (event.Funeral && event.Funeral.ID > 0 && 'Funeral')
            || (event.ReflectionRoom && event.ReflectionRoom.Cremation && event.ReflectionRoom.Cremation.ID > 0 && 'Cremation');
        const record = (recordType === 'Funeral' && event.Funeral) || (recordType === 'Cremation' && event.ReflectionRoom.Cremation);
        const deceasedName = (recordType === 'Funeral' && joinDefined([record.FirstName, record.Surname], ' '))
            || (recordType === 'Cremation' && joinDefined([record.Deceased.FirstName, record.Deceased.Surname], ' '));
        const theTitle =
            view === 'Locations'
                ? (deceasedName) || event.Title
                : (event.Appointment && event.Appointment.Reason) || event.Title;

        const eventStart =
            (event.Service && event.Service.Start) ||
            (event.Viewing && event.Viewing.Start) ||
            (event.Disposal && event.Disposal.Start);
        const eventType = (event.Service && event.Service.Type) || (event.Viewing && event.Viewing.Type);
        const subType = eventType ? (['Service', 'Viewing'].includes(eventType) ? '' : ': ' + eventType) : '';
        return (
            <Fragment>
                {view === 'Locations' ? (
                    <Fragment>
                        <div
                            className={open ? 'focus-visible' : ''}
                            tabIndex={0}
                            onKeyDown={this.handlePopoverOpen}
                            onClick={this.handlePopoverOpen}
                            style={{
                                borderLeft: '3px solid ' + category.color,
                                backgroundColor: category.backgroundColor + 'AA'
                            }}
                        >
                            <div className={'category'} style={{ color: category.color }}>
                                {category.label}
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div
                            className={open ? 'focus-visible' : ''}
                            tabIndex={0}
                            onKeyDown={this.handlePopoverOpen}
                            onClick={this.handlePopoverOpen}
                            style={{
                                borderLeft: '3px solid ' + category.color,
                                background: `linear-gradient(${category.backgroundColor},${category.backgroundColor +
                                    'AA'})`
                            }}
                        >
                            <div className={'category'} style={{ color: category.color }}>
                                {category.label}
                            </div>
                            <div className={'title'}>
                                {record && record.LegacyKey ? `${record.LegacyKey} | ${deceasedName}` : theTitle}
                            </div>
                            <div className={'details'}>
                                {moment(event.Start).format('h:mma')} - {moment(event.End).format('h:mma')}
                                <br />
                                {event.LocationFlattened}
                            </div>
                        </div>
                    </Fragment>
                )}
                <Popover
                    elevation={0} // applied to 'cal-card' instead
                    marginThreshold={24}
                    className={classes.popover}
                    open={open}
                    onClose={this.handlePopoverClose}
                    anchorEl={anchorEl}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: this.state.anchorTop, left: this.state.anchorLeft }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    TransitionComponent={DraggableGrow}
                >
                    <div className={'cal-card'}>
                        <div className={'cal-card-head'} style={{ backgroundColor: category.color }}>
                            <span className={'cal-card-drag drag-handle'} title={'Drag'}>
                                <Icon>drag_indicator</Icon>
                            </span>

                            <span
                                tabIndex={0}
                                className={'cal-card-close'}
                                title={'Close'}
                                onKeyDown={this.handlePopoverClose}
                                onClick={this.handlePopoverClose}
                            >
                                <Icon>cancel</Icon>
                            </span>

                            <Typography>
                                <small style={{ color: '#ffffffBB' }}>
                                    {category.label}
                                    {subType}
                                </small>
                            </Typography>
                            <Typography style={{ fontSize: '1.125rem' }}>
                                {(!!record && !!record.LegacyKey && (
                                    <span>
                                        <LinkButton
                                            title={'View Record'}
                                            variant="link"
                                            classes={{
                                                //root: classes.moveLink,
                                                enabled: classes.niceLink
                                            }}
                                            text={record.LegacyKey}
                                            href={this.viewRecordURL(event, category)}
                                            onClick={e => this.props.history.push(this.viewRecordURL(event, category))}
                                        />
                                        {' | '}
                                    </span>
                                ))}
                                {deceasedName || theTitle}
                            </Typography>
                        </div>
                        <GatedComponent canEditCode={'FM_ACCESS_Appointments_Edit'}>
                            {isEnabled => {
                                if (
                                    event.Type !== 'Appointment' &&
                                    event.Type !== 'Unavailable' &&
                                    event.Type !== 'AshCollection'
                                )
                                    isEnabled = false; // don't edit FM appointments
                                return (
                                    <Fab
                                        size="small"
                                        title={!!isEnabled ? 'Edit' : 'Cannot edit'}
                                        className={classes.edit}
                                        disabled={!isEnabled}
                                        style={{ backgroundColor: category.color, outline: 'revert' }}
                                        onClick={e => (!!isEnabled ? this.handleEdit(e) : null)}
                                    >
                                        <Icon>{!isEnabled ? 'lock' : 'edit'}</Icon>
                                    </Fab>
                                );
                            }}
                        </GatedComponent>
                        <div className={'cal-card-body'}>
                            <Typography className={classes.cardtext}>
                                <Icon className={classes.cardicon} style={{ color: category.color }}>
                                    access_time
                                </Icon>
                                {moment(event.Start).format('dddd, D MMMM Y')}
                                <br />
                                {moment(event.Start).format('h:mma')}
                                {' - '}
                                {moment(event.End).format('h:mma')}
                                {!moment(event.Start).isSame(moment(event.End), 'day') && (
                                    <small>{moment(event.End).format(' dddd, D MMMM Y')}</small>
                                )}
                            </Typography>
                            {event.LocationFlattened &&
                                (!event.Appointment || event.Appointment.LocationType !== 'Phone') && (
                                    <Typography className={classes.cardtext}>
                                        <Icon className={classes.cardicon} style={{ color: category.color }}>
                                            map
                                        </Icon>
                                        {event.LocationFlattened}
                                        {eventStart && (
                                            <small>
                                                <br />
                                                {category.label} starts {niceTimeFromString(eventStart).toLowerCase()}
                                            </small>
                                        )}
                                    </Typography>
                                )}
                            {event.Appointment && event.Appointment.Phone && (
                                <Typography className={classes.cardtext}>
                                    <Icon className={classes.cardicon} style={{ color: category.color }}>
                                        phone
                                    </Icon>
                                    {event.Appointment.Phone}
                                </Typography>
                            )}
                            {this.renderPeople(category)}
                            {this.renderComments()}
                        </div>

                        <GatedComponent showComponentCode={'FM_ACCESS_Appointments_Delete'}>
                            {() => {
                                // canCancel if just an appointment
                                const canCancel =
                                    !category.type ||
                                    ['PreFuneral', 'Unavailable', 'Appointment', 'VenueUnavailable'].indexOf(
                                        category.type
                                    ) > -1;

                                // canDestroy the staff allocation if we lost the calendar event
                                const canDestroyLostAllocation =
                                    !canCancel &&
                                    view !== 'Locations' &&
                                    !(
                                        getAppointmentOptionType(event.Type) === 'StaffAllocation' &&
                                        [
                                            'Service',
                                            'Viewing',
                                            'Disposal',
                                            'Committal',
                                            'Refreshments',
                                            'ReflectionRoom'
                                        ].find(e => Number(event[e] && Number(event[e].ID)))
                                    );

                                return (
                                    (canCancel || canDestroyLostAllocation) && (
                                        <span
                                            tabIndex={0}
                                            className={'cal-card-del'}
                                            title={'Delete'}
                                            onClick={() => this.handleDelete(canDestroyLostAllocation)}
                                        >
                                            <DeleteIcon style={{ width: 18, height: 18 }} />
                                        </span>
                                    )
                                );
                            }}
                        </GatedComponent>
                    </div>
                </Popover>
            </Fragment>
        );
    }

    renderComments = () => {
        const { event, classes } = this.props;
        return (
            <Typography className={classes.cardtext} style={{ marginLeft: -32 }}>
                <small>
                    {isRelatedObjectDefined(event.Appointment)
                        ? event.Appointment.Comment
                        : event.Allocation || event.CemeterySection || event.Comment || event.Notes}
                </small>
            </Typography>
        );
    };
    renderPeople = category => {
        const { event, classes, view } = this.props;
        if (view && view === 'Locations') {
            return (
                event.StaffAllocations &&
                event.StaffAllocations.map((allocation, imy) => {
                    const desc = allocation.Allocation;
                    let name = '';
                    let icon = 'check_circle_outline';
                    if ((allocation.Member && allocation.Member.FirstName) || allocation.Member.Surname) {
                        name = joinDefined([allocation.Member.FirstName, allocation.Member.Surname], ' ');
                        icon = 'person';
                    }

                    let start = null;
                    let end = null;
                    if (allocation.Start !== allocation.End) {
                        start = moment(allocation.Start).format('h:mma');
                        end = moment(allocation.End).format('h:mma');
                    }

                    return (
                        <Typography className={classes.cardtext} key={'allocation_' + imy}>
                            <Icon className={classes.cardicon} style={{ color: category.color }}>
                                {icon}
                            </Icon>
                            {name}
                            <small>
                                {start && end && ` ${start} - ${end} `}
                                {desc && ' | ' + desc}
                            </small>
                        </Typography>
                    );
                })
            );
        } else {
            const others =
                (!!event.Appointment &&
                    Number(event.Appointment.ID) &&
                    (event.Appointment.Members.length > 0
                        ? event.Appointment.Members.filter(e => !!e.ID && e.ID !== event.Member.ID)
                        : [])) ||
                (event[category.type] &&
                    event[category.type].StaffAllocations &&
                    event[category.type].StaffAllocations.filter(
                        e => !!e.Member.ID && e.Member.ID !== event.Member.ID
                    ).map(e => e.Member)) ||
                [];

            return (
                <Typography className={classes.cardtext}>
                    <Icon className={classes.cardicon} style={{ color: category.color }}>
                        person
                    </Icon>
                    {event.Member.FirstName} {event.Member.Surname}
                    {others.length > 0 && (
                        <small>
                            <br />
                            Also with{' '}
                            {joinDefined(
                                others.map(eventMember =>
                                    joinDefined([eventMember.FirstName, eventMember.Surname], ' ')
                                ),
                                ', '
                            )}
                        </small>
                    )}
                </Typography>
            );
        }
    };

    handlePopoverOpen = evt => {
        const rect = evt.currentTarget.getBoundingClientRect();
        if (['Enter', ' '].includes(evt.key)) {
            evt.preventDefault();
            evt = { ...evt, clientX: rect.left + 10, clientY: rect.top + 10 };
        } else if (evt.key) return;
        this.setState({ anchorEl: evt.target, anchorTop: evt.clientY, anchorLeft: evt.clientX });
    };

    handlePopoverClose = evt => {
        if (evt && evt.key && !['Enter', ' ', 'Escape'].includes(evt.key)) {
            return;
        }
        this.setState({ anchorEl: null });
    };

    handleDelete = canDestroyLostAllocation => {
        const { event } = this.props;
        if (event.Appointment && Number(event.Appointment.ID))
            // cancel appointment
            this.handleDeleteAppointment(event.Appointment.ID);
        else if (!canDestroyLostAllocation)
            // cancel calendarevent
            this.handleDeleteCalendarEvent(event.ID);
        // delete staff allocation because lost calendar event
        else this.handleDeleteStaffAllocation(event.ID);
    };

    handleDeleteAppointment = eventID => {
        if (!window.confirm('Are you sure you want to delete this appointment?')) return null;
        this.doDelete(eventID, deleteAppointment);
    };

    handleDeleteCalendarEvent = eventID => {
        if (!window.confirm('Are you sure you want to delete this event?')) return null;
        this.doDelete(eventID, deleteCalendarEvent);
    };

    handleDeleteStaffAllocation = eventID => {
        if (!window.confirm('Are you sure you want to delete this lost allocation?')) return null;
        this.doDelete(eventID, deleteStaffAllocation);
    };

    doDelete = async (eventID, mutation) => {
        const me = this;
        const deletion = await getClient()
            .mutate({ mutation: mutation, variables: { IDs: [eventID] } })
            .then(data => {
                const { onMutate } = me.props;
                if (onMutate) onMutate();
                me.handlePopoverClose();
            });
        return !!deletion;
    };

    isAllDay = event => {
        if (
            moment(event.start).valueOf() ===
                moment(event.start)
                    .startOf('day')
                    .valueOf() &&
            moment(event.end).valueOf() ===
                moment(event.end)
                    .startOf('day')
                    .valueOf()
        ) {
            // all-day event. could be multiday.
            return true;
        } else if (!moment(event.start).isSame(event.end, 'day')) {
            // multi-day event, but with start & end times.
            return false;
        } else {
            // single day event with start & end times
            return null;
        }
    };

    handleEdit(e) {
        const { event, editCalendarAllocationsById, editAppointmentById, editCalendarEventById } = this.props;

        if (this.props.view === 'Locations') {
            if (event.__typename === 'CalendarEvent') editCalendarEventById(event.ID);
            else editCalendarAllocationsById(event.ID);
        } else {
            editAppointmentById(event.Appointment.ID);
        }

        this.handlePopoverClose(e);
    }

    viewRecordURL = (event, category) => {
        if (event.ReflectionRoom && event.ReflectionRoom.ID > 0
            && event.ReflectionRoom.Cremation && event.ReflectionRoom.Cremation.ID > 0) {
            return '/cremations/' + event.ReflectionRoom.Cremation.ID;
        }
        if (event.Funeral && event.Funeral.ID > 0) {
            const url = '/funeral/' + event.Funeral.LegacyKey + '/' + event.Funeral.ID;
            const tab = category.type === 'Refreshments' ? 'optionals' : 'funeral-details';
            return url + '/' + tab;
        }
        return null;
    };
}

const styles = () => ({
    popover: {
        '& > div': {
            margin: '-1rem',
            backgroundColor: 'transparent'
        }
    },
    edit: {
        position: 'absolute',
        margin: '-1.25rem 18.75rem',
        color: 'white'
    },
    cardtext: {
        margin: '0.5rem 0'
    },
    cardicon: {
        position: 'absolute',
        margin: '1px 0 0 -1.88rem',
        fontSize: '20px'
    },
    moveLink: {
        fontSize: '0.875rem',
        marginTop: -20,
        position: 'absolute',
        right: '1.75rem'
    },
    niceLink: {
        '& > span': {
            color: '#ffffff'
        },
        '& > span:hover': {
            color: '#ffffffAA'
        }
    }
});

export default compose(withRouter, withEditAppointment, withStyles(styles))(ViewEvent);
