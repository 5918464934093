import gql from 'graphql-tag';
import { capitalize } from '../../utils/strings';

export const createTableQuery = (queryName, fragment, dataObject, isFilterable, isSortable) => {
    const capitalizedQueryName = capitalize(queryName);
    const filterByInput = isFilterable ? `$filterBy: [Filters]` : '';
    const sortByInput = isSortable ? `$sortBy: [SortField]` : '';
    const filterByInputVar = isFilterable ? `filterBy: $filterBy` : '';
    const sortByInputVar = isSortable ? `sortBy: $sortBy` : '';

    const sortDirection = isSortable ? 'SortDirection: ' + getTypeQuery(`${dataObject}SortFields`) : '';
    const filterOptions = isFilterable ? 'FilterOptions: ' + getTypeQuery(`${dataObject}FilterFields`) : '';
    const customFilters = isFilterable ? 'CustomFilters: ' + getTypeQuery(`${dataObject}CustomFilters`) : '';

    const sortByInfo = isSortable ? `sortByInfo {
    					field
    					direction
    				}` : '';
    const filterByInfo = isFilterable ? `filterByInfo {
    					field
    					value
    				}` : '';

    return gql`
		${fragment}
		query ${capitalizedQueryName}(
            $limit: Int
            $offset: Int
            $contains: String
			$filters: [Filters] 
			${filterByInput}
			${sortByInput}
		){
			${queryName}(
			    limit: $limit
			    offset: $offset 
			    contains: $contains
				filters: $filters
				${filterByInputVar}
				${sortByInputVar}
			){
				edges {
					node {
						ID
						...${fragment.definitions[0].name.value}
					}
				}
				pageInfo {
					hasNextPage
					hasPreviousPage
					totalCount
				}
				${filterByInfo}
				${sortByInfo}
			}
			${sortDirection}
	        ${filterOptions}
			${customFilters}
		}
	`;
};

const getTypeQuery = typeName => {
    return `
		__type(name:"${typeName}"){
			name
			kind
			inputFields {
				name
				description
				type {
					name
					inputFields {
						name
					}
					enumValues {
					    name
					    description
					}
				}
			}
		}`;
};
