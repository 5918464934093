import gql from 'graphql-tag';
import Address from './Address';
import Member from './Member';

export default gql`
	fragment DispatchDate on CremationOrderDispatchDate {
		ID
		Date
		Time
		ContainmentStyle
		Ashes
		DispatchMethod
		AshesCollectedBy
		FuneralDirector
		Comment
		DispatchComplete
		DestinationAddress {
			...Address
		}
		AssignedStaff {
			...Member
		}
	}
	${Address}
	${Member}
`;
