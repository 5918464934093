import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '../../components/form/TextField';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Grid from '../../components/layout/Grid';
import Button from '../../components/form/Button';
import CremationOrderFragment from '../../fragments/CremationOrder';
import NotesReadOnly from '../../components/form/NotesReadOnly';
import EditIcon from '../../components/icon/EditIcon';
import StaffAutoComplete from '../../components/form/StaffAutoComplete';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import AlertBar from '../../components/form/AlertBar';
import UserCircle from '../../components/form/UserCircle';
import { joinDefined } from '../../utils/strings';
import AlertModal from '../../components/modal/AlertModal';
import ReadonlyField from '../../components/form/ReadonlyField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { shortDateFromString } from '../../utils/date';

const maxHours = 72;
const setFinalInsertionDate = (form) => {
    const dateReq = form.getField('CremationDate');
    const dateAct = form.getField('DeliveryDate');
    const dateEst = form.getField('EstimatedDeliveryDate');
    const dateIns = form.getField('EstimatedInsertionDate');
    const sameDay = form.getField('SameDayCremation');
    if (sameDay) {
        form.setField({ EstimatedInsertionDate: dateAct || dateEst || dateReq });
    } else if (!dateIns) {
        form.setField({ EstimatedInsertionDate: dateReq });
    }
    return sameDay
        ? (dateAct || dateEst || dateReq)
        : moment(dateAct || dateEst || dateReq).add(maxHours, 'hours').format('YYYY-MM-DD');
};

class CremationActionTab extends Component {
    state = {
        editCR: true,
        showDeliveryReceivedByField: false,
        showCremationOperatorField: false,
        showConfirmComplete: false,
        finalInsertionDate: null
    };

    static getDerivedStateFromProps(newProps, oldState) {
        const { form } = newProps;
        const { currentRecordID } = oldState;
        if (!currentRecordID && form) {
            const setRecordID = form.getField('ID');
            const finalInsertionDate = setFinalInsertionDate(form);
            return ({
                currentRecordID: setRecordID,
                finalInsertionDate
            });
        }
        return null;
    }

    render() {
        const { showConfirmComplete } = this.state;
        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </ColumnLayout>

                <AlertModal
                    variant="save"
                    open={showConfirmComplete}
                    title="Prompt"
                    primaryAction="Yes, Mark as Complete"
                    onClickPrimaryAction={() => this.handleMarkCremationAsComplete()}
                    secondaryAction="Actually, not just yet"
                    onClickSecondaryAction={() => this.setState({ showConfirmComplete: false })}
                    onClose={() => this.setState({ showConfirmComplete: false })}
                >
                    <p>You're about to mark this cremation as complete.</p>
                    <p>Are you sure?</p>
                </AlertModal>
            </Fragment>
        );
    }

    renderLeftColumn() {
        return (
            <Fragment>
                {this.renderDeliveryDate()}
                {this.renderCremationActionNotes()}
            </Fragment>
        );
    }

    renderDeliveryDate() {
        const { form } = this.props;

        return (
            <Fragment>
                <Grid container bucket>
                    <Grid item>
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <h4>Estimated Delivery</h4>
                        </Inline>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Estimated Delivery Date"
                            type="date"
                            form={form}
                            name="EstimatedDeliveryDate"
                            onChange={this.handleFinalInsertion}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Estimated Delivery Time"
                            type="time"
                            form={form}
                            name="EstimatedDeliveryTime"
                            onChange={this.handleFinalInsertion}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Estimated Deceased Weight"
                            type="number"
                            form={form}
                            name="EstimatedDeceasedWeight"
                            InputProps={{ startAdornment: <InputAdornment position="start">KG</InputAdornment> }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Delivery by whom"
                            form={form}
                            name="EstimatedDeliveredBy"
                        />
                    </Grid>
                </Grid>

                <Grid container bucket>
                    <Grid item>
                        <h4>Actual Delivery</h4>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField label="Actual Delivery Date" type="date" form={form} name="DeliveryDate"
                                   onChange={this.handleFinalInsertion} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField label="Actual Delivery Time" type="time" form={form} name="DeliveryTime"
                                   onChange={this.handleFinalInsertion} />
                    </Grid>

                    <Grid item margin={'-18px 0 0 0'}>
                        <Button
                            variant="link-orange"
                            onClick={() => this.insertDateAndTime('DeliveryDate', 'DeliveryTime')}
                        >
                            Use current date and time
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Actual Deceased Weight" type="number" form={form} name="DeceasedWeight"
                            InputProps={{ startAdornment: <InputAdornment position="start">KG</InputAdornment> }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        {this.renderDeliveryReceivedByField()}
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderDeliveryReceivedByField() {
        const { form } = this.props;
        const { showDeliveryReceivedByField } = this.state;

        let deliveryReceivedBy = form.getState('DeliveryReceivedBy');

        if (!!deliveryReceivedBy && !showDeliveryReceivedByField) {
            let profileName = joinDefined([deliveryReceivedBy.FirstName, deliveryReceivedBy.Surname], ' ');
            let profileAbbreviation = '';

            if (profileName) {
                profileAbbreviation = profileName.match(/\b\w/g);
            }

            return (
                <ReadonlyField label="Delivery Received By">
                    <UserCircle
                        user={profileName}
                        abbreviation={profileAbbreviation}
                        onDelete={() =>
                            this.handleDeleteStaff('DeliveryReceivedBy') &&
                            this.setState({ showDeliveryReceivedByField: true })
                        }
                    />
                </ReadonlyField>
            );
        }

        return (
            <StaffAutoComplete
                label="Delivery Received By Whom"
                placeholder="Search by name..."
                selectProps={{ multiple: false }}
                onSelect={(_, user) => this.handleSelectStaff('DeliveryReceivedBy', user)}
                form={form}
                name="DeliveryReceivedBy"
            />
        );
    }

    renderRightColumn() {
        const { form } = this.props;
        const query = gql`
            query ReadOneCremationReady($id: ID!) {
                readOneCremationOrder(ID: $id){
                    ID
                    ReadyForCremation
                }
            }
        `;
        return (
            <Query query={query} variables={{ id: form.getState('ID') }} fetchPolicy="network-only">
                {({ data }) => {
                    const readOneCremationOrder = data && data.readOneCremationOrder;
                    const { ReadyForCremation } = readOneCremationOrder || {};
                    return (<>
                        {this.getAdditionalActions(ReadyForCremation)}
                        {this.renderInsertionDate(ReadyForCremation)}
                        {this.renderCRNumber()}
                        {this.renderCremationEndDate(ReadyForCremation)}
                    </>);
                }}
            </Query>
        );
    }

    renderInsertionDate(readyForCremation) {
        const { form } = this.props;
        const { finalInsertionDate } = this.state;
        const sameDayCremation = form.getField('SameDayCremation');
        const witnessCremation = 'Required' === form.getField('WitnessOfInsertionRequired');
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Insertion</h4>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Estimated Insertion Date"
                        type="date"
                        form={form}
                        name="EstimatedInsertionDate"
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <p>
                        Insertion is required {!sameDayCremation
                        ? <>max {maxHours} hours after</>
                        : 'SAME DAY as'} delivery.
                        <br /><strong>Final Insertion Date: </strong>
                        {!finalInsertionDate ? 'Pending delivery' : shortDateFromString(finalInsertionDate)}
                    </p>
                    {!!witnessCremation && (
                        <AlertBar variant="warning">Witness of Insertion requested</AlertBar>
                    )}
                </Grid>

                {(readyForCremation && (
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Actual Insertion Date" type="date" form={form} name="InsertionDate" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField label="Actual Insertion Time" type="time" form={form} name="InsertionTime" />
                        </Grid>

                        <Grid item margin={'-18px 0 0 0'}>
                            <Button
                                variant="link-orange"
                                onClick={() => this.insertDateAndTime('InsertionDate', 'InsertionTime')}
                            >
                                Use current date and time
                            </Button>
                        </Grid>
                    </Fragment>
                ))}
            </Grid>
        );
    }

    renderCRNumber() {
        const { form } = this.props;
        const { editCR } = this.state;
        return (
            <Grid container bucket>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                        <h4>Cremation Number</h4>

                        <IconButton className="icon" onClick={() => this.setState({ editCR: !editCR })}>
                            <EditIcon />
                        </IconButton>
                    </Inline>
                </Grid>
                <Grid item>
                    {((!editCR || !!form.getField('CremationNumber')) && (
                        <TextField
                            label="CR Number"
                            placeholder="Update CR Number..."
                            type="number"
                            form={form}
                            name="CremationNumber"
                            readOnly={!!editCR}
                        />
                    )) || <ReadonlyField label="CR Number">Not yet generated</ReadonlyField>}
                </Grid>
            </Grid>
        );
    }

    renderCremationEndDate(readyForCremation) {
        const { form } = this.props;

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Action Ended</h4>
                </Grid>

                {(readyForCremation && (
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <TextField label="End Date" type="date" form={form} name="CremationEndDate" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField label="End Time" type="time" form={form} name="CremationEndTime" />
                        </Grid>

                        <Grid item margin={'-18px 0 0 0'}>
                            <Button
                                variant="link-orange"
                                onClick={() => this.insertDateAndTime('CremationEndDate', 'CremationEndTime')}
                            >
                                Use current date and time
                            </Button>
                        </Grid>

                        {this.renderCremationCompleteField(readyForCremation)}
                    </Fragment>
                )) || (
                    <Grid item>
                        <p>Order is not marked as Cremation Ready.</p>
                    </Grid>
                )}
            </Grid>
        );
    }

    renderCremationOperator() {
        return (
            <Fragment>
                <Grid item>
                    <h5>Cremation Operator</h5>
                </Grid>
                <Grid item>
                    {this.renderCremationOperatorField()}
                </Grid>
            </Fragment>
        );
    }

    renderCremationOperatorField() {
        const { form } = this.props;
        const { showCremationOperatorField } = this.state;

        let cremationOperator = form.getState('CremationOperator');

        if (!!cremationOperator && !showCremationOperatorField) {
            let profileName = joinDefined([cremationOperator.FirstName, cremationOperator.Surname], ' ');
            let profileAbbreviation = '';

            if (profileName) {
                profileAbbreviation = profileName.match(/\b\w/g);
            }

            return (
                <UserCircle
                    user={profileName}
                    abbreviation={profileAbbreviation}
                    onDelete={() =>
                        this.handleDeleteStaff('CremationOperator') &&
                        this.setState({ showCremationOperatorField: true })
                    }
                />
            );
        }

        return (
            <StaffAutoComplete
                placeholder="Search for a staff name here"
                selectProps={{ multiple: false }}
                onSelect={(_, user) => this.handleSelectStaff('CremationOperator', user)}
                form={form}
                name="CremationOperator"
            />
        );
    }

    renderCremationActionNotes() {
        const { form } = this.props;

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Cremation Action Notes</h4>
                </Grid>

                <Grid item>
                    <TextField
                        placeholder="Notes are written here..."
                        multiline
                        name="CremationActionNotes"
                        form={form}
                    />
                </Grid>

                {form.getField('OldCremationActionNotes') && (
                    <Grid item>
                        <h4>Note History</h4>
                    </Grid>
                )}

                {form.getField('OldCremationActionNotes') && (
                    <Grid item>
                        <NotesReadOnly name="OldCremationActionNotes" form={form} />
                    </Grid>
                )}
            </Grid>
        );
    }

    renderCremationCompleteField(readyForCremation) {
        const { form } = this.props;

        if (form.getState('CremationComplete')) {
            return (
                <Grid item>
                    <Inline>
                        <AlertBar variant="success">This cremation has been marked as complete</AlertBar>
                    </Inline>
                </Grid>
            );
        }

        return (
            <Grid item>
                <Button
                    disabled={form.isDirty || form.getField('CremationPending') || !readyForCremation}
                    variant="primary"
                    onClick={() => this.setState({ showConfirmComplete: true })}
                >
                    Mark as Cremation Completed...
                </Button>
            </Grid>
        );
    }

    getAdditionalActions(readyForCremation) {
        const { form } = this.props;
        const missing = form.getField('HasMissingCertificates');
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Action Start</h4>
                </Grid>
                {(!!missing && !readyForCremation &&
                    <Grid item>
                        <p>
                            Cannot start until marked as Cremation Ready.<br />
                            Please check the Summary tab for details.
                        </p>
                    </Grid>
                ) || (
                    <Grid item>
                        <p>
                            Order is {!readyForCremation && 'not'} marked as Cremation Ready.
                        </p>
                    </Grid>
                )}

                {this.renderCremationOperator()}
            </Grid>
        );
    }

    handleMarkCremationAsComplete() {
        const { form } = this.props;

        form.setState({ CremationComplete: true });
        form.save();
        this.setState({ showConfirmComplete: false });
    }

    insertDateAndTime(dateFieldName, timeFieldName) {
        const { form } = this.props;

        form.setState({
            [dateFieldName]: moment().format('YYYY-MM-DD'),
            [timeFieldName]: moment().format('HH:mm')
        });
    }

    handleSelectStaff(propertyName, staff) {
        const { form } = this.props;

        if (!!staff) {
            delete staff['__typename'];

            this.setState({ showCremationOperatorField: false });

            form.setState({ [propertyName]: staff });
        }
    }

    handleDeleteStaff(propertyName) {
        const { form } = this.props;

        form.setState({
            [propertyName + 'ID']: null,
            [propertyName]: null
        });
    }

    handleFinalInsertion = () => {
        const { form } = this.props;
        setFinalInsertionDate(form);
    };
}

export default {
    id: 'CremationAction',
    label: 'Cremation Action',
    component: withStyles({})(CremationActionTab),
    fragment: CremationOrderFragment,
    onLoad: data => {
        // create a stub to detect new notes on save!
        if (!!data.CremationActionNotes) {
            data.OldCremationActionNotes = '' + (data.CremationActionNotes || '');
        }
        delete data.CremationActionNotes;
    },
    formatSaveData: (saveData, state) => {
        if (saveData.CremationOperator === null) {
            saveData.CremationOperatorID = null;
            delete saveData['CremationOperator'];
        }
        if (saveData.DeliveryReceivedBy === null) {
            saveData.DeliveryReceivedByID = null;
            delete saveData['DeliveryReceivedBy'];
        }
        if (!state.CremationNumber) {
            saveData.CremationNumber = 0; // can't be empty.
        }
    }
};
