import { getClient } from './utils/apollo';
import gql from 'graphql-tag';
import { CurrentUserFragment } from './components/GatedComponent';

export const introspectionQueryResultData = require('./fragments/introspection/fragmentTypesDev');

export const serverUrl = process.env.REACT_APP_CMS_SERVER || 'http://cms-' + window.location.hostname;

const query = gql`
    ${CurrentUserFragment}
    query readStartup {
        readOneSiteConfig {
            ID
            #AdminFee
            #BondPercent
            CMBrandID
            #CremationManagerDomain
            #FuneralArrangerDomain
            FuneralManagerDomain
            MVC2CMID
            MaxUploadBytes
            ReactDeployment
        }
        readOfficesUsernames {
            nodes {
                ID
                ForegroundColour
                IsRetired
                Logo
                OfficeColour
                Phone
                RegistrantSuffix
                Title
                ... on OfficesUsername {
                    Branches {
                        nodes {
                            ID
                        }
                    }
                }
                ... on OfficesBranch {
                    BrandID
                }
            }
        }
        readCurrentUser {
            ...CurrentUserFragment
        }
    }
`;

export function getMaxUploadSize() {
    const readOneSiteConfig = getSiteConfig();
    return (readOneSiteConfig && readOneSiteConfig.MaxUploadBytes) || 8388608; // 8MB is typical server default
}

export function getOffice() {
    const { CMBrandID } = getSiteConfig();
    return getOffices().find(obj => CMBrandID === obj.ID);
}

/**
 * Retrieve the site config from cache.
 * If it has not loaded yet, catch the error and return null.
 * @returns {Object} the SiteConfig
 */
export function getSiteConfig() {
    let result = {};
    try {
        const data = getClient().readQuery({ query }) || {};
        if (data && data.readOneSiteConfig) {
            result = { ...data.readOneSiteConfig };
        }
    } catch (e) {
        // send back empty object
    }
    return result;
}

/**
 * Retrieve the offices from cache.
 * If it has not loaded yet, catch the error and return null.
 * @returns {{ID,Title,OfficeColour,RegistrantSuffix,IsRetired}[]} the list of brands and branches
 */
export function getOffices() {
    let result = [];
    try {
        const data = getClient().readQuery({ query }) || {};
        if (data && data.readOfficesUsernames) {
            result = data.readOfficesUsernames.nodes
                .map(node => ({ ...node }))
                .sort(({ Title: aTitle }, { Title: bTitle }) => (aTitle > bTitle ? 1 : -1));
        }
    } catch (e) {
        // send back empty array
    }
    return result;
}

/**
 * Retrieve the current user details from cache.
 * If it has not loaded yet, catch the error and return null.
 * @returns {ID,FirstName,Surname,Email} the user details
 */
export function getCurrentUser() {
    let result = {};
    try {
        const data = getClient().readQuery({ query }) || {};
        if (data && data.readCurrentUser) {
            result = { ...data.readCurrentUser };
        }
    } catch (e) {
        // send back empty object
    }
    return result;
}

export function loadSiteConfig() {
    return getClient().query({ query });
}

export function isLocalServer() {
    if (serverUrl.indexOf('.local') !== -1) return true;
    if (serverUrl.indexOf('.webdev') !== -1) return true;
    return false;
}

export function isLocalClient() {
    if (window.location.host.indexOf('localhost:300') === 0) return true;
    return false;
}
