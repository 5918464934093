/* eslint-disable no-console */
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import DebounceLink from 'apollo-link-debounce';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client';
import { setSnackbarMessage } from '../context/SnackbarMessage';
import { introspectionQueryResultData, serverUrl } from '../environment';
import history from './history';
import { clearToken, getToken, isSignedIn, isTokenExpired, refreshToken } from './sessions';

export const GQL_ENDPOINT_PRIMARY = '/graphql';
export const GQL_ENDPOINT_AUTH_JWT = '/graphql';
export const GQL_ENDPOINT_ADMIN = '/graphql';
export const GQL_ENDPOINT_DEFAULT = '/graphql';

let SERVICE_URL = serverUrl + GQL_ENDPOINT_PRIMARY;

let client;

/**
 * This link is executed after a request fails.
 * We use this to determine if the request has failed due to authorization.
 * If so we reset the client and redirect to the sign in page.
 */
const handleError = ({ graphQLErrors, networkError }) => {
    console.error('gql error occured');

    if (graphQLErrors) {
        let graphQLErrorsString = '';
        graphQLErrors.map(({ message, locations, path }) => {
            if (graphQLErrorsString !== '') {
                graphQLErrorsString += '\n \n';
            }
            graphQLErrorsString += `[GraphQL error]: Message: ${message}`;
            return graphQLErrorsString;
        });
        // Omg snackbar from anywhere.
        setSnackbarMessage(graphQLErrorsString);
        console.error(graphQLErrorsString);
    }

    // A bit hacky, but whenever you get an error due to being unauthorized the
    // error message from the graphql server always starts with `Cannot view`
    if (
        graphQLErrors &&
        graphQLErrors.some(e => e.message.startsWith('Cannot view') || e.message.endsWith('view access not permitted'))
    ) {
        clearToken();
        resetClient();
    }

    if (networkError) {
        let errorsString = `[Network error]: ${networkError}`;
        setSnackbarMessage(errorsString);
        console.error(errorsString);
    }
};

/**
 * This link will debounce graphql requests which have a `debounceKey`
 * in the context.
 */
const getDebounceLink = () => {
    return new DebounceLink(250);
};

/**
 * This link actually makes the HTTP request, after the request has been
 * processed by the other link middleware.
 */
const getHttpLink = () => {
    let URL = SERVICE_URL;
    /* TODO: remove this API profiling config in production ? */
    if (document.cookie.indexOf('XDEBUG_PROFILE') >= 0) {
        URL += '?XDEBUG_PROFILE=1';
    }
    return new HttpLink({
        uri: URL,
        fetch: refreshFetch
    });
};

/**
 * A custom fetch function which adds auth headers to the request and
 * will attempt to refresh the token before a request if it's found
 * to be expired.
 */
const refreshFetch = (uri, options) => {
    // TODO: It'd be better if we could do this after a failed request with 401
    // instead of doing this preemptively before every outgoing request
    if (isTokenExpired() && JSON.parse(options.body).operationName !== 'RefreshToken') {
        // If the current stored token has expired and this is not the request to refresh
        // the token, then we execute the refresh request before the original request
        return refreshToken(getAuthClient()).then(success => {
            return success === false ? resetClient() : executeFetch(uri, options);
        });
    }

    return executeFetch(uri, options);
};

const executeFetch = (uri, options) => {
    const token = getToken();
    if (token && token.value) options.headers.authorization = `Bearer ${token.value}`;
    return fetch(uri, options);
};

export const resetClient = () => {
    // We need to redirect to the /sign-in page first to un-mount all components with active queries
    history.push('/sign-in');
    authClient.resetStore().catch(() => {}); // doesn't work, but whatevs. see below.
    return client.resetStore().catch(() => {
        // if we failed to clear the store (shouldn't happen),
        // then hard refresh the page instead
        window.location.replace('/sign-in');
    });
};

function dataIdFromObject(obj) {
    const typeName = obj.__typename.toLowerCase();
    let idVal;
    switch (typeName) {
        default:
            idVal = obj.ID || obj.id || obj.Id || obj.Key;
    }
    if (!idVal) {
        if (
            !typeName.endsWith('connection') &&
            !typeName.endsWith('connection1') &&
            !typeName.endsWith('join') &&
            !typeName.endsWith('edge') &&
            !typeName.endsWith('pageinfo') &&
            !typeName.endsWith('filterbyinfo') &&
            !typeName.endsWith('sortinfo') &&
            !typeName.endsWith('filtersinfo') &&
            !typeName.endsWith('__type') &&
            !typeName.endsWith('__inputvalue') &&
            !typeName.endsWith('__enumvalue') &&
            !typeName.endsWith('multivaluefield') &&
            !typeName.endsWith('multivaluefieldcomposite') &&
            !typeName.endsWith('_multivaluefieldlist') &&
            !typeName.endsWith('metricsresult') &&
            0 !== Number(idVal)
        )
            console.warn('ID missing on object ' + obj.__typename, obj);
        return undefined;
    }

    // catch joins and cache separately. gotta catch 'em all!
    if (!!obj._join) {
        for (let key in obj._join) {
            if (key !== '__typename') {
                idVal += ':' + obj._join[key].__typename + ':' + obj._join[key].ID;
                break;
            }
        }
    }

    return `${obj.__typename}:${idVal}`;
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});

export const getClient = () => {
    if (client) return client;

    client = new ApolloClient({
        link: onError(handleError)
            .concat(getDebounceLink())
            .concat(getHttpLink()),
        cache: new InMemoryCache({ dataIdFromObject, fragmentMatcher })
    });

    window.__client = client;

    client.onResetStore(() => {
        history.push('/');
    });

    return client;
};

/**
 * Client for JWT authentication GQL endpoint `/authenticate/graphql`.
 */
let authClient;

export const getAuthClient = () => {
    // needed to reset data if not logged in, resetStore() doesn't work.
    if (authClient && isSignedIn()) return authClient;

    authClient = new ApolloClient({
        link: onError(handleError)
            .concat(getDebounceLink())
            .concat(
                new HttpLink({
                    uri: serverUrl + GQL_ENDPOINT_AUTH_JWT,
                    fetch: refreshFetch
                })
            ),
        cache: new InMemoryCache()
    });

    return authClient;
};

/**
 * Client for generic jobs GQL endpoint `/graphql`.
 */
let myClient;

export const getMyClient = () => {
    // needed to reset data if not logged in, resetStore() doesn't work.
    if (myClient && isSignedIn()) return myClient;

    myClient = new ApolloClient({
        link: onError(handleError)
            .concat(getDebounceLink())
            .concat(
                new HttpLink({
                    uri: serverUrl + GQL_ENDPOINT_DEFAULT,
                    fetch: refreshFetch
                })
            ),
        cache: new InMemoryCache({ dataIdFromObject, fragmentMatcher })
    });

    return myClient;
};

/**
 * Client for Assets GQL endpoint `/admin/graphql`.
 */
let assetsClient;

export const getAssetsClient = () => {
    if (assetsClient) return assetsClient;

    const uploadLink = createUploadLink({
        uri: serverUrl + GQL_ENDPOINT_ADMIN,
        fetch: refreshFetch
    });

    assetsClient = new ApolloClient({
        link: ApolloLink.from([onError(handleError), getDebounceLink(), uploadLink]),
        cache: new InMemoryCache()
    });

    return assetsClient;
};

export const getServiceURLHostname = () => {
    return extractHostname(SERVICE_URL);
};

const extractHostname = url => {
    let hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf('//') > -1) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
};
