import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { compose, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Paper from '../../components/layout/Paper';
import Grid from '../../components/layout/Grid';
import DataFormView from '../../components/DataFormView';
import RecordDetailsTab from './RecordDetailsTab';
import ContactDetailsTab from './ContactDetailsTab';
import CremationActionTab from './CremationActionTab';
import BillingTab from './BillingTab';
import SummaryTab from './SummaryTab';
import CorrespondenceTab from './CorrespondenceTab';
import Button from '../../components/form/Button';
import ViewHeader from '../../components/header/ViewHeader';
import { createNewCremationOrder } from './CremationConstants';
import BackIcon from '../../components/icon/BackIcon';
import AlertBar from '../../components/form/AlertBar';
import CremationOrder from '../../fragments/CremationOrder';
import { joinDefined } from '../../utils/strings';
import GoFuneral from '../../components/GoFuneral';
import Spinner from '../../components/loading/Spinner';
import DocumentsIcon from '../../components/icon/DocumentsIcon';
import TemplateSelectionModal from './Modals/TemplateSelectionModal';
import Inline from '../../components/layout/Inline';
import RecordDrawer from '../../components/workQueue/RecordDrawer';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../components/ErrorFallback';

/**
 * Tab Definition
 */
const CremationTabs = [
    RecordDetailsTab,
    ContactDetailsTab,
    CremationActionTab,
    BillingTab,
    SummaryTab,
    CorrespondenceTab
];

class Cremation extends Component {
    state = {
        showTemplateSelectionModal: false
    };

    constructor(props) {
        super(props);

        this.cremationRecord = null;
    }

    render() {
        const { match } = this.props;
        const { showTemplateSelectionModal } = this.state;
        return (
            <Grid container>
                <Query query={query} variables={{ id: match.params.id }}>
                    {results => {
                        const { loading, data } = results;
                        if (loading)
                            return (
                                <div>
                                    <Spinner />
                                </div>
                            );

                        const cremation = data && data.readOneCremationOrder;

                        this.cremationRecord = cremation;

                        return this.renderContent();
                    }}
                </Query>

                <TemplateSelectionModal
                    open={showTemplateSelectionModal}
                    cremationID={match.params.id}
                    onClose={() => this.handleCloseTemplateSelectionModal()}
                />
            </Grid>
        );
    }

    renderContent() {
        const { match } = this.props;
        return (
            <Fragment>
                <Grid item>
                    {this.renderHeader()}
                    {this.renderAlerts()}
                </Grid>
                <Grid item>
                    <Paper elevation={0}>
                        <DataFormView
                            tabs={CremationTabs}
                            createNewFunc={createNewCremationOrder}
                            objectType="CremationOrder"
                            itemId={match.params.id}
                            name="Cremation"
                            onCreated={e => this.onCreateNew(e)}
                            context={this}
                        />
                    </Paper>
                </Grid>
            </Fragment>
        );
    }

    onCreateNew(cremationOrder) {
        const { history } = this.props;
        history.push(`cremation/${cremationOrder.ID}`);
    }

    renderHeader() {
        const cremation = this.cremationRecord;
        let subtitle = '';
        if (cremation && cremation.LegacyKey && cremation.Enquiry) {
            subtitle = joinDefined(
                [
                    cremation.LegacyKey,
                    cremation.Deceased.FirstName,
                    cremation.Deceased.MiddleName,
                    cremation.Deceased.Surname
                ],
                ' '
            );
        }
        const { history } = this.props;
        return (
            <ViewHeader variant="record" title="Cremation" subtitle={subtitle}>
                <Inline>
                    <Button variant="secondary" onClick={() => history.push('/cremations/')}>
                        <BackIcon />
                        Back
                    </Button>
                    {!!cremation && (
                        <Fragment>
                            <GoFuneral funeral={cremation.Funeral} />
                            <Button
                                variant="primary icon-button white shadow"
                                size="lg"
                                title="Generate Merge Documents"
                                onClick={() => this.handleShowTemplateSelectionModal()}
                            >
                                <DocumentsIcon />
                            </Button>
                            <RecordDrawer cremationId={cremation.ID} legacyKey={cremation.LegacyKey} />
                        </Fragment>
                    )}
                </Inline>
            </ViewHeader>
        );
    }

    renderAlerts() {
        const cremation = this.cremationRecord;
        if (!cremation) return null;
        const approvedAllRequiredDocs = !cremation.HasMissingCertificates;
        const cancelled = cremation.CremationCancelled;
        return (
            <Fragment>
                {(cancelled && <AlertBar variant="error">This record was cancelled.</AlertBar>) ||
                    (!approvedAllRequiredDocs && (
                        <AlertBar variant="warning">
                            Attention required: Unable to perform cremation because important documents are missing or
                            require review.
                        </AlertBar>
                    ))}
            </Fragment>
        );
    }

    handleShowTemplateSelectionModal() {
        this.setState({ showTemplateSelectionModal: true });
    }

    handleCloseTemplateSelectionModal() {
        this.setState({ showTemplateSelectionModal: false });
    }
}

const query = gql`
    ${CremationOrder}
    query readOneCremationOrder($id: ID!) {
        readOneCremationOrder(ID: $id) {
            ID
            ...CremationOrder
        }
    }
`;

export default withErrorBoundary(compose(withRouter, withStyles({}))(Cremation), { fallback: ErrorFallback });
