import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import Grid from '../../components/layout/Grid';
import Paper from '../../components/layout/Paper';
import Button from '../../components/form/Button';
import ViewHeader from '../../components/header/ViewHeader';
import FilterModal from './Modals/FilterModal';
import { getBrandHomeByKey } from '../../utils/brands';
import DataTable from '../../components/dataTable/DataTable';
import { joinDefined, PAGE_ORDER_REQUESTS, prettyPhone } from '../../utils/strings';
import { getProperty } from '../../utils/objects';
import { niceDateFromString, niceDateTimeFromString } from '../../utils/date';
import AttentionDot from '../../components/table/AttentionDot';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../components/ErrorFallback';

class CremationPendingList extends Component {
    state = {
        showFilterModal: false
    };

    render() {
        const { showFilterModal } = this.state;

        return (
            <Grid container>
                <Grid item>{this.renderHeader()}</Grid>
                <Grid item>
                    <Paper elevation={0}>
                        <Grid item>{this.renderCremationListingsTable()}</Grid>
                    </Paper>
                </Grid>
                <FilterModal
                    open={!!showFilterModal}
                    anchorEl={showFilterModal}
                    onClose={() => this.setState({ showFilterModal: false })}
                />
            </Grid>
        );
    }

    renderHeader() {
        return (
            <ViewHeader title="Enquiries" subtitle="Order Requests">
                {/*<Button*/}
                {/*	variant="primary"*/}
                {/*	size="lg"*/}
                {/*	onClick={event =>*/}
                {/*		this.setState({ showFilterModal: event.currentTarget })*/}
                {/*	}*/}
                {/*>*/}
                {/*	<FilterIcon />*/}
                {/*	Filter*/}
                {/*</Button>*/}
            </ViewHeader>
        );
    }

    renderCremationListingsTable() {
        const filters = [
            { field: 'CremationPending', value: '1' }
        ];
        return (
            <Fragment>
                <Grid item>
                    <Grid container>
                        <Grid item>
                            <DataTable
                                dataObject="CremationOrder"
                                queryName="readCremationOrders"
                                fragment={cremationFragment}
                                isFilterable
                                isSortable
                                columns={cremationRequestColumns}
                                variables={{ limit: 10, filters, filterBy: [] }}
                                brandProperty="LegacyKey" //the property to determine the brand from
                                searchVariable="contains"
                                tableTitle="Pending Orders"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

const cremationRequestColumns = [
    {
        label: 'Funeral Ref',
        propertyPath: 'LegacyKey',
        renderCell: (row, value) => {
            const approvedAllRequiredDocs = !row.HasMissingCertificates;
            const urgent = row.Status === 'Urgent' || row.Status === 'Overdue';
            return (
                <Fragment>
                    {(!approvedAllRequiredDocs || !!urgent) && <AttentionDot />}
                    <Button href={PAGE_ORDER_REQUESTS + row.ID} variant="link-orange">
                        {value || row.ID}
                    </Button>
                </Fragment>
            );
        }
    },
    {
        label: 'Deceased',
        propertyPath: 'Deceased.Surname',
        renderCell: row => <span>{row.NameOfDeceased}</span>
    },
    {
        label: 'Cremation Date/Time',
        propertyPath: 'CremationDate',
        renderCell: row => (
            <span>{niceDateTimeFromString(joinDefined([row.CremationDate, row.CremationTime], ' '))}</span>
        )
    },
    {
        label: 'Applicant',
        propertyPath: 'Client.Surname',
        renderCell: row => (
            <span>
                {row.Client && joinDefined([row.Client.FirstName, row.Client.MiddleName, row.Client.Surname], ' ')}
                {row.Client &&
                    (row.Client.PrimaryNumber || row.Client.SecondaryNumber) &&
                    (row.Client.FirstName || row.Client.MiddleName || row.Client.Surname) &&
                    ' - '}
                {row.Client && prettyPhone(joinDefined([row.Client.PrimaryNumber, row.Client.SecondaryNumber], ' / '))}
            </span>
        )
    },
    {
        label: 'Date of Service',
        propertyPath: 'Funeral.DateOfService',
        renderCell: row => <span>{row.Funeral && niceDateFromString(row.Funeral.DateOfService)}</span>
    },
    {
        label: 'Status',
        renderCell: row => <span>{row.Status}</span>
    },
    {
        label: 'Brand',
        renderCell: row => <span>{getBrandHomeByKey(getProperty(row, 'LegacyKey')).label}</span>
    }
];

const cremationFragment = gql`
    fragment CremationOrderPending on CremationOrder {
        ID
        LegacyKey
        CremationDate
        CremationTime
        HasMissingCertificates
        NameOfDeceased
        Status
        Funeral {
            ID
            LegacyKey
            DateOfService
        }
        Client {
            ID
            FirstName
            MiddleName
            Surname
            PrimaryNumber
            SecondaryNumber
            Email
        }
    }
`;

export default withErrorBoundary(compose(withRouter, withStyles({}))(CremationPendingList), { fallback: ErrorFallback });
