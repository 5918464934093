import React from 'react';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import { joinDefined, stringIsNullOrEmpty } from '../../utils/strings';
import { CloseIcon, TickIcon } from '../IconIndex';

export default withStyles({})(({ variant, product, image, productTitle, productCost, selectedID, onSelect }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `image--${variant}`;
    const isTicked = product && selectedID === product.ID;
    return (
        <div className={joinDefined(['image', cssVariant, isTicked ? 'check' : ''], ' ')}>
            <Fab
                size="small"
                color="primary"
                aria-label="Check"
                className={isTicked ? 'fab check' : 'fab'}
                title={isTicked ? 'Item is selected' : 'Item is not selected'}
                onClick={() => onSelect(product)}
            >
                {isTicked ? <TickIcon /> : <CloseIcon />}
            </Fab>
            <div className="product-thumbnail">
                {!!image && <div className="product-image" style={{ backgroundImage: `url(${image}` }} />}

                <div className="card-title">
                    <p>{productTitle}</p>
                </div>
            </div>
            <div className="product-cost">
                <p>Cost:&nbsp;${productCost}</p>
            </div>
        </div>
    );
});
