export const createNewCremationOrder = () => {
    return {
        CremationDate: null,
        CremationTime: null,
        ContainmentStyle: null,
        Recipient: null,
        DestinationAddress: null,
        DeliveryDate: null,
        DeliveryTime: null,
        DeceasedWeight: null,
        DeliveredBy: null,
        DeliveryReceivedBy: null,
        InsertionDate: null,
        InsertionTime: null,
        CremationEndDate: null,
        CremationNumber: null,
        CremationEndTime: null,
        CremationHeldOver: null,
        CremationOperator: null,
        CremationActionNotes: null,
        CremationComplete: null,
        AdditionalNotes: null,
        AshLocations: [],
        SpecialInstructions: [],
        DispatchDates: [],
        CremationDateHistories: [],
        Deceased: {
            FirstName: null,
            MiddleName: null,
            Surname: null,
            Gender: null,
            ResidentialAddress: {
                Name: null,
                AddressLine1: null,
                AddressLine2: null,
                Suburb: null,
                Postcode: null,
                State: null,
                Country: null
            },
            DateOfBirth: null,
            Situtation: null,
            DeathStatus: null,
            DateOfDeath1: null,
            DateOfDeath2: null,
            PlaceOfDeath: null,
            TransferFrom: null
        },
        Enquiry: {
            LegacyKey: null,
            EnquiryByBusiness: null,
            EnquiryType: null,
            Notes: null
        },
        Client: {
            RelationshipToDeceased: null,
            Title: null,
            FirstName: null,
            MiddleName: null,
            Surname: null,
            PrimaryNumber: null,
            SecondaryNumber: null,
            Email: null,
            PostalAddress: {
                Name: null,
                AddressLine1: null,
                AddressLine2: null,
                Suburb: null,
                Postcode: null,
                State: null,
                Country: null
            },
            ResidentialAddress: {
                Name: null,
                AddressLine1: null,
                AddressLine2: null,
                Suburb: null,
                Postcode: null,
                State: null,
                Country: null
            }
        },
        AuthorisedContact: {
            RelationshipToDeceased: null,
            Title: null,
            FirstName: null,
            MiddleName: null,
            Surname: null,
            PrimaryNumber: null,
            SecondaryNumber: null,
            Email: null,
            PostalAddress: {
                Name: null,
                AddressLine1: null,
                AddressLine2: null,
                Suburb: null,
                Postcode: null,
                State: null,
                Country: null
            },
            ResidentialAddress: {
                Name: null,
                AddressLine1: null,
                AddressLine2: null,
                Suburb: null,
                Postcode: null,
                State: null,
                Country: null
            }
        }
    };
};

export const AssociatedOrderOptions = [
    { label: 'Plaque Order', value: 'plaqueOrder' },
    { label: 'Enquiry', value: 'enquiry' },
    { label: 'Product Order', value: 'productOrder' }
];

export const ProductStatusOptions = [{ label: 'Unknown', value: 'unknown' }, { label: 'Pending', value: 'pending' }];

export const getRequiredDocumentTypes = (type = '', form = null) => {
    const requiredDocuments = [];
    const fromDate = form && form.getField('CremationDate');
    if (new Date(fromDate) > new Date('2024-10-01 00:00:00')) {
        requiredDocuments.push({
            description: 'Contract for Cremation',
            fieldName: 'FileCremContractForm',
            canUpload: true
        });
    }
    requiredDocuments.push({
        description: 'Crematorium Booking Form',
        fieldName: 'FileCremBookingForm'
    });
    requiredDocuments.push({
        description: 'Statutory Declaration Form (A-Form)',
        fieldName: 'FileStatDecForm'
    });
    if (type !== 'Coroner') {
        requiredDocuments.push({
            description: 'Medical Certificate Cause of Death (MCCD)',
            fieldName: 'FileMedCertCause'
        });
        requiredDocuments.push({
            description: 'Attending Practitioner Certificate of Cremation',
            fieldName: 'FileCremCertificate'
        });
        requiredDocuments.push({
            description: 'Refereeing Doctor Form',
            fieldName: 'FileRefereeingDoctorForm'
        });
    } else if (type !== 'Doctor') {
        requiredDocuments.push({
            description: 'Coroner\'s Disposal Order',
            fieldName: 'FileCorDisposalOrder'
        });
        requiredDocuments.push({
            description: 'Coroner\'s Cremation Permit',
            fieldName: 'FileCorCremPermit'
        });
    }
    return requiredDocuments;
};

export const getLatestRequiredDocuments = (certType, allDocs) => {
    const requiredTypes = getRequiredDocumentTypes(certType);
    const sortedDocs = []
        .concat(allDocs || [])
        .filter(e => !!e)
        .sort((a, b) => (a.Created < b.Created ? 1 : -1)); // newest ones first
    return requiredTypes.map(
        type => sortedDocs.find(doc => type.fieldName === doc.Name) || { Name: type.fieldName, Status: null } // get first doc for each type, or return a stub
    );
};
